import React, { useState, useEffect } from "react";
import {
  Container,
  Navbar,
  Image,
  Nav,
  Form,
  FormControl,
  Button,
  Tab,
  Tabs,
  Row,
  Col,
  Table,
} from "react-bootstrap";
import Moment from "react-moment";
import Upcoming from "./Upcoming";
import Completed from "./Completed";
import Cancelled from "./Cancelled";
import Flightdetail from "../components/Flightdetail";
import FareBox from "../components/FareBox";
import modulecss from "../cssmodules/UpcommingScreen.module.css";
import Menubox from "../components/Menubox";
import Traveller from "../components/Traveller";
import { useDispatch, useSelector } from "react-redux";
import {
  getBookingDetailAction,
  listUserFlights,
} from "../actions/userdetailAction";
import Loader from "../components/Loader";

import { ONEWAY, ROUNDTRIP, MULTICITY } from "../Constant";
import NavbarComp from "../components/NavbarComp";
import Header from "../components/Header";
import HotelDetail from "../components/HotelDetail";
import swal from "@sweetalert/with-react";
import BusDetail from "../components/BusDetail";


function UpcommingScreen({ match, history }) {

  // get the type of booking (flight, hotel etc)
  var type = JSON.parse(localStorage.getItem("type"));
  var cancelPolicy = "";


  // authentication login data
  var authData = JSON.parse(sessionStorage.getItem("authData"));

  // fetching booking details data from api
  const bookData = useSelector((state) => state.getBookingDetailStore);
  const { error, loading, bookingData } = bookData;
  var bookTest = "";
  bookTest = bookingData;


  // cancellation policy for hotel
  if (type == "ht" && bookingData) {
    cancelPolicy = bookingData.itemInfos.HOTEL.hInfo.ops[0].cnp;
    // console.log("$$$$$$$$$$$$$$$$$");
    // console.log(cancelPolicy);
  }
  debugger;

  var bookingDbData = history.location.state.bookingDetail;

  const tripType = history.location.state.triptype;
  const dispatch = useDispatch();
  // const indxNo = match.params.indx
  // const triptype = match.params.type
  // console.log(tripType);

  useEffect(() => {
    // console.log("DDDDDDDDDDDDDDD")
    // console.log(bookingDbData)
    debugger;
    if (JSON.parse(localStorage.getItem("type")) == "ft") {
      let reqData = {
        book_id: bookingDbData.Flight_booking_id,
        ApiTyp: "TRIPJACK",
        type:"flight",
        trip_id:bookingDbData.id
      };
      dispatch(
        getBookingDetailAction(
          reqData,
          "TRIPJACK",
          "/cancel-tripjack/flight_detail/"
        )
      );
    } 
    else if(JSON.parse(localStorage.getItem("type")) == "ht"){
      let reqData = {
        bookingId: bookingDbData.Hotel_booking_id,
        ApiTyp: "TRIPJACK",
        type:"hotel"
      };
      dispatch(
        getBookingDetailAction(
          reqData,
          "TRIPJACK",
          "/hotel/hotel_booking_details/"
        )
      );
    }
    else if(JSON.parse(localStorage.getItem("type")) == "bus"){
      let reqData = {
        bookingdata: bookingDbData,
        ApiTyp: "TBO",
        type:"bus"
      };
      dispatch(
        getBookingDetailAction(
          reqData,
          "TRIPJACK",
          "/hotel/hotel_booking_details/"
        )
      );
    }

    localStorage.setItem(
      "tripType",
      JSON.stringify(history.location.state.triptype)
    );
  }, [dispatch]);

  // refund policy for hotel
  function getHotelRefundData() {
    swal({
      buttons: {
        cancel: null,
      },
      content: (
        <Table
          className={cancelPolicy.ifra == true ? "mt-4" : "mt-4"}
          striped
          bordered
          hover
          size="sm"
        >
          <thead>
            <tr>
              <th>From Date</th>
              <th>To Date</th>
              <th>Cancellation Charge</th>
            </tr>
          </thead>
          <tbody>
            {cancelPolicy.pd.map((policy, pindx) => {
              var departure_date = policy.fdt.split("T")[0];
              var arrival_date = policy.tdt.split("T")[0];

              // console.log("policy");
              // console.log(policy);
              return (
                <tr>
                  <td>
                    <Moment format="ddd, DD MMM YYYY">{departure_date}</Moment>
                  </td>
                  <td>
                    <Moment format="ddd, DD MMM YYYY">{arrival_date}</Moment>
                  </td>
                  <td>₹{policy.am}</td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      ),
    });
  }

  const loaderData = (loaderData) => {
    // console.log("hello");
  };

  if (bookingData) {

    const bookData = {
      fromcity: bookingDbData.from_city,
      tocity: bookingDbData.to_city,
      from_airport: bookingDbData.from_airport,
      to_airport: bookingDbData.to_airport,
    };
    // console.log("Asasasasasasasasa");
    // console.log(cancelPolicy);
    return (
      <div>
        {/* setting up header for dashboard */}
        <Header
          token={authData.token}
          phone_number={authData.phone_number}
          nameVal={authData.name}
          country_code={authData.country_code}
        />
        <div className="jumbotron1">
          {/* setting up nav pannel for dashboard */}
          <NavbarComp
            token={authData.token}
            phone_number={authData.phone_number}
            nameVal={authData.name}
            country_code={authData.country_code}
          />
          <br />
          <Container>
            <div className="card mt-1">
              <Tabs
                defaultActiveKey="home"
                id="uncontrolled-tab-example"
                className="mb-3"
              >
                <Tab
                  eventKey="home"
                  title="My Booking"
                  className="tab-pane active"
                >
                  <Row>
                    <Col md={8} className="p-4">
                      {type == "ft" ? (
                        <Flightdetail
                          bookingDetails={bookingDbData}
                          tripType={tripType}
                          tripType_sec={history.location.state.tripType_sec}
                        />
                      ) :type=="bus"?(
                        <BusDetail
                          bookingDetails={bookingDbData}
                          apiBookingData={bookingData}
                          bookType={tripType}
                          loaderData={false}
                        />
                      ):(
                        <HotelDetail
                          bookingDetails={bookingDbData}
                          apiBookingData={bookingData}
                          bookType={tripType}
                          loaderData={false}
                        />
                      )}
                    </Col>

                    <Col md={4} style={{ paddingRight: "1em" }}>
                      <Menubox type={type} bookingDetails={bookingDbData} />
                      <br />
                      {type == "ht" && cancelPolicy ? (
                        <div className="">
                          <h5>
                            {" "}
                            <span style={{ fontWeight: "bold" }}>
                              Cancellation Charges
                            </span>
                          </h5>
                          <Table
                            className={
                              cancelPolicy.ifra == true ? "mt-4" : "mt-4"
                            }
                            striped
                            bordered
                            hover
                            size="sm"
                          >
                            <thead>
                              <tr className="text-center">
                                <th>Due Date</th>

                                <th>Charges</th>
                              </tr>
                            </thead>
                            <tbody>
                              {cancelPolicy.pd.map((policy, pindx) => {
                                var departure_date = policy.fdt.split("T")[0];
                                var arrival_date = policy.tdt.split("T")[0];

                                // console.log("policy");
                                // console.log(policy);
                                return (
                                  <tr>
                                    <td className="col-9">
                                      <Row>
                                        <Col
                                          className="text-center"
                                          style={{ paddingRight: "0" }}
                                        >
                                          <Moment format="DD MMM YYYY">
                                            {departure_date}
                                          </Moment>
                                        </Col>
                                        <Col
                                          className="col-3"
                                          style={{ paddingLeft: "0" }}
                                        >
                                          <span className="web_color">
                                            <hr />
                                          </span>
                                        </Col>
                                        <Col
                                          style={{
                                            paddingRight: "0",
                                            paddingLeft: "0",
                                          }}
                                        >
                                          <Moment format="DD MMM YYYY">
                                            {arrival_date}
                                          </Moment>
                                        </Col>
                                      </Row>
                                    </td>
                                    <td style={{ textAlign: "center" }}>₹{policy.am}
                                    </td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </Table>
                        </div>
                      ) : (
                        ""
                      )}

                      {/* <FareBox bookinginfo={bookingData} /> */}
                    </Col>
                  </Row>
                  <Row>
                    <Col></Col>
                  </Row>
                </Tab>
              </Tabs>
            </div>
          </Container>
        </div>
      </div>
    );
  } else {
    return <div></div>;
  }
}

export default UpcommingScreen;
