import React from 'react';
import { Container, Navbar, Image, Nav, Form, FormControl, Button, Tab, Tabs, Card, Col, Row, ListGroup } from "react-bootstrap";
import modulecss from '../cssmodules/FareBox.module.css'

function FareBox({bookinginfo}) {
    
    // const bookingData = JSON.parse(window.localStorage.getItem('bookDetailsKey'));
    const bookingData = bookinginfo;

    const fareData = bookingData.itemInfos.AIR.totalPriceInfo.totalFareDetail.fC;
    // console.log(fareData);
    return (
        <Row className={modulecss.mainDiv}>
            <Col>
                <Card className="m-4">
                    <Card.Body>
                        <ListGroup variant="flush p-2">
                            <ListGroup.Item>
                                <Row>
                                    <Col>
                                        <span>Base Fare</span>
                                    </Col>
                                    <Col className={modulecss.alignCls}>
                                        <span>{fareData.BF}</span>
                                    </Col>
                                </Row>
                            </ListGroup.Item>
                            {/* <ListGroup.Item>
                                <Row>
                                    <Col>
                                        <span>Adult(s) (1 X ₹9070)</span>
                                    </Col>
                                    <Col className={modulecss.alignCls}>
                                        <span>9070</span>
                                    </Col>
                                </Row>
                            </ListGroup.Item> */}
                            <ListGroup.Item>
                                <Row>
                                    <Col>
                                        <span>Tax & Charge</span>
                                    </Col>
                                    <Col className={modulecss.alignCls}>
                                        <span>{fareData.TAF}</span>
                                    </Col>
                                </Row>
                            </ListGroup.Item>
                            <ListGroup.Item>
                                <Row>
                                    <Col>
                                        <span className="subHeadCls">Total</span>
                                    </Col>
                                    <Col className={modulecss.alignCls}>
                                        <strong>{fareData.TF}</strong>
                                    </Col>
                                </Row>
                            </ListGroup.Item>

                        </ListGroup>
                    </Card.Body>
                </Card>
            </Col>
        </Row>
    )
}

export default FareBox
