import Header from "./components/Header";
import "./App.css";
import "./assets/css/another_typewriter.css";
import "./assets/css/poppins.css";
import "./assets/css/fontawesome.css";
// import "./assets/css/fontawesome.min.css";
import Dashboard from "./Screens/Dashboard";
import { Container, Navbar, Image, Nav, Form, FormControl, Button, Tab, Tabs, Row, Col } from "react-bootstrap";
import { HashRouter as Router, Route } from "react-router-dom";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

var PERMITTED_DOMAIN = "http://localhost/MyBranch/HotelGohoptrip/GoHopTrip_Website/hotel_home";
/**
 * Receiving message from other domain
 */
window.addEventListener('message', function(event) {
    if (event.origin === PERMITTED_DOMAIN) {
        //var msg = JSON.parse(event.data);
        // var msgKey = Object.keys(msg)[0];
        if (event.data) {
            localStorage.setItem("localstorage", event.data);
        } else {
            localStorage.removeItem("localstorage");
        }
    }

});


function App() {
  
  return (
    <div className="App">
      {/* <Header /> */}
      <Dashboard />
    </div>
  );
}

export default App;
