import React, { useState, useLayoutEffect, useEffect } from "react";
import {
  Container,
  Navbar,
  Image,
  Nav,
  Form,
  FormControl,
  Button,
  Tab,
  Tabs,
  Row,
  Col,
  Table,
} from "react-bootstrap";
import Moment from "react-moment";
import { WEBSITE_URL } from "../Constant";
import modulecss from "../cssmodules/UserProfile.module.css";
import "../cssmodules/UserProfile.css";
import "../App.css";
// import "../helpers.js";
import { useDispatch, useSelector } from "react-redux";
import { getUserDataAction } from "../actions/userdetailAction";
import axios from "axios";
import { API_URL, SUPPORT_URL } from "../Constant";
import $ from 'jquery'
import swal from "@sweetalert/with-react";
import Swal from 'sweetalert2';
import {DisableIncDecNumber} from '../Helpers.js';
// DisableIncDecNumber
function BusinessregistrationForm() {

   // funtion to call API and save data
   const register = async (data) => {
      const save_entry = await axios.post( API_URL + '/accounts/CreateAndUpdateCorporateRegistration/', data);
      if(save_entry.data == "Created Successfully"){
         Swal.fire({
            html: "Registration requested successfully,<br> You can log in once you are activated!",
            confirmButtonText: "OK",
         })
         .then(() => {
            window.location.href = WEBSITE_URL
         })
      }
      else if(save_entry.data == "Registration already requested"){
         Swal.fire({
            html: "Registration already requested!",
            confirmButtonText: "OK",
         })
      }
      else{
         Swal.fire({
            text: save_entry.data,
            confirmButtonText: "OK",
         })
      }
   };

   // function for checking gst number format
   function checksum(g) {
       let regTest = /\d{2}[A-Z]{5}\d{4}[A-Z]{1}[A-Z\d]{1}[Z]{1}[A-Z\d]{1}/.test(
           g
       );
       if (regTest) {
           let a = 65,
               b = 55,
               c = 36;
           return Array["from"](g).reduce((i, j, k, g) => {
               var p =
                   (p =
                       (j.charCodeAt(0) < a
                           ? parseInt(j)
                           : j.charCodeAt(0) - b) *
                       ((k % 2) + 1)) > c
                       ? 1 + (p - c)
                       : p;
               return k < 14
                   ? i + p
                   : j ==
                         ((c = c - (i % c)) < 10
                             ? c
                             : String.fromCharCode(c + b));
           }, 0);
       }
       return regTest;
   }

   // function to restrict text field
   const restrictText = (e) => {
      debugger
      if(/[a-z ]/i.test(e.key) == false){
        e.preventDefault()
      }
      // return /[a-z ]/i.test(e.key)
   }

   // create request format after validating form data
   const setData = () => {
      var flag = 1
      $(".required").each(function(index,element){
         if(element.value == ""){
            debugger
            // if(element.classList.contains('cre_gst_number') || element.classList.contains('cre_gst_file') || element.classList.contains('cre_tan_number')){
            //    if(!$('.gst_field').hasClass("hide") && $('.gst_field').val() != ""){
            //       flag = 0
            //       swal("Please fill all required fields")
            //       return false
            //    }
            // }
            // else{
               flag = 0
               swal("Please fill all required fields")
               return false;
            // }
         }
         // if current input is contact number
         else if(element.classList.contains('cre_contact_no')){
            var no_reg = new RegExp("^[7-9]{1}[0-9]{9}$");
            if (element.value.match(no_reg) == null) {
               flag = 0;
               swal("Please check contact number!")
               return false
            }
         }
         // if current input is email
         else if(element.classList.contains('cre_emailid')){
            var email_reg = new RegExp(/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/);
            if (element.value.match(email_reg) == null) {
               flag = 0;
               swal("Please check email id!")
               return false
            }
         }
         // if current input is gst
         else if(element.classList.contains('cre_gst_number')){
            // valid gstin is 27AAHCG6111E1ZX
            if (checksum(element.value) != true) {
               flag = 0;
               swal("GST number is invalid")
               return false
            }
         }

         // if current input is tan number
         else if(element.classList.contains('cre_tan_number')){
            var tan_reg = new RegExp('[A-Za-z]{4}[0-9]{5}[A-Za-z]{1}');
            // valid tan number is PDES03028F
            if (element.value.match(tan_reg) == null) {
               flag = 0;
               swal("TAN number is invalid!")
               return false
            }
         }

         else{
            flag = 1
         }
      })

      if(flag == 1){
         var formdata = new FormData()
         formdata.append('cre_agency_name', $('#cre_agency_name').val())
         formdata.append('cre_registration_address', $('#cre_registration_address').val())
         formdata.append('cre_logo', $('#cre_logo')[0].files[0])
         formdata.append('cre_pan_Card_copy', $('#cre_pan_Card_copy')[0].files[0])
         formdata.append('cre_gst_number', $('#cre_gst_number').val())
         if(!$(".gst_field").hasClass("hide")){
            formdata.append('cre_gst_file', $('#cre_gst_file')[0].files[0])
         }
         formdata.append('cre_tan_number', $('#cre_tan_number').val())
         formdata.append('cre_emailid', $('#cre_emailid').val())
         formdata.append('cre_contact_no', $('#cre_contact_no').val())
         formdata.append('cre_fname', $('#cre_fname').val())
         formdata.append('cre_lname', $('#cre_lname').val())
         formdata.append('cre_business_type', $('#cre_business_type').val())
         register(formdata)
      }
   }

   //if gst is checked make gst fields required
   const show_gst = (event) => {
      if(event.target.checked == true){
         $('.gst_field').removeClass("hide")
         $('.gst_field').find('.cre_gst_number').addClass("required")
         $('.gst_field').find('.cre_gst_file').addClass("required")
      }
      else{
         $('.gst_field').addClass("hide")
         $('.gst_field').find('.cre_gst_number').removeClass("required")
         $('.gst_field').find('.cre_gst_file').removeClass("required")
      }
   }

   useEffect(() => {
      DisableIncDecNumber()
     }, [])

  return(
    <>
      <div class="container b2b_register_container">
         <Row className="row1">
            <Col className="col1">
               <div class="form-group">
                  <label for="cre_agency_name">Agency Name <span style={{'color':'red'}}>*</span></label>
                  <input type="text" class="form-control required" id="cre_agency_name" name="cre_agency_name" placeholder="Agency Name" onKeyPress={(e) => {restrictText(e)}}/>
               </div>
               <br/>
               <div class="form-group">
                  <label for="cre_registration_address">Address <span style={{'color':'red'}}>*</span></label>
                  <textarea class="form-control required" id="cre_registration_address" name="cre_registration_address" placeholder="Address"></textarea>
               </div>
               <br/>
               <div class="form-group">
                  <label for="cre_logo">Logo <span style={{'color':'red'}}>*</span></label>
                  <input type="file" class="form-control required" id="cre_logo" name="cre_logo" placeholder="Logo" />
                  <span><small class="text-muted">jpg, png, webp</small></span>
               </div>
               <br/>
               <div class="form-group">
                  <label for="cre_pan_Card_copy">Pan Card Copy <span style={{'color':'red'}}>*</span></label>
                  <input type="file" class="form-control required" id="cre_pan_Card_copy" name="cre_pan_Card_copy" placeholder="Pan Card Copy" />
                  <span><small class="text-muted">jpg, png, webp, pdf, doc, docx, jpg, png, xlsx, xls</small></span>
               </div>
               <br/>
               <div class="form-group">
                  <div class="form-check" style={{'padding':'0'}}>
                     <input type="checkbox" class="required have_gst" id="have_gst" name="have_gst" placeholder="" onChange={(e) => show_gst(e)} style={{'margin-right':'10px'}}/>
                     <label for="have_gst">Gst Available<span style={{'color':'red'}}>*</span></label>
                  </div>
               </div>
               <br/>
               <div class="form-group gst_field hide">
                  <label for="cre_gst_number">Gst Number <span style={{'color':'red'}}>*</span></label>
                  <input type="text" class="form-control cre_gst_number" id="cre_gst_number" name="cre_gst_number" placeholder="Gst Number" />
                  <br/>
               </div>
               <div class="form-group gst_field hide">
                  <label for="cre_gst_file">Gst File <span style={{'color':'red'}}>*</span></label>
                  <input type="file" class="form-control cre_gst_file" id="cre_gst_file" name="cre_gst_file" placeholder="Gst File" />
               </div>
            </Col>
            <Col className="col2">
               <div class="form-group">
                  <label for="cre_tan_number">Tan Number</label>
                  <input type="text" class="form-control cre_tan_number" id="cre_tan_number" name="cre_tan_number" placeholder="Tan Number" />
               </div>
               <br/>
               <div class="form-group">
                  <label for="cre_emailid">Email <span style={{'color':'red'}}>*</span></label>
                  <input type="email" class="form-control required cre_emailid" id="cre_emailid" name="cre_emailid" placeholder="Email" />
               </div>
               <br/>
               <div class="form-group">
                  <label for="cre_contact_no">Contact No. <span style={{'color':'red'}}>*</span></label>
                  <input type="number" class="form-control required cre_contact_no" id="cre_contact_no" name="cre_contact_no" placeholder="Contact No." />
               </div>
               <br/>
               <div class="form-group">
                  <label for="cre_fname">First Name <span style={{'color':'red'}}>*</span></label>
                  <input type="text" class="form-control required" id="cre_fname" name="cre_fname" placeholder="First Name" onKeyPress={(e) => {restrictText(e)}}/>
               </div>
               <br/>
               <div class="form-group">
                  <label for="cre_lname">Last Name <span style={{'color':'red'}}>*</span></label>
                  <input type="text" class="form-control required" id="cre_lname" name="cre_lname" placeholder="Last Name" onKeyPress={(e) => {restrictText(e)}}/>
               </div>
               <br/>
               <div class="form-group">
                  <label for="cre_business_type">Business Type <span style={{'color':'red'}}>*</span></label>
                  <select class="form-control required" id="cre_business_type" name="cre_business_type" placeholder="Name">
                     <option value="Travel_Agency">Travel Agency</option>
                     <option value="Corporate">Corporate</option>
                  </select>
               </div>
            </Col>
         </Row>
              
         <Row className="row2">
            <Col style={{"margin":"10px"}}>
               <button onClick={setData} class="b2b_register_btn theme_purple_btn btn">Register</button>
            </Col>
         </Row>
      </div>
    </>
    )
}

export default BusinessregistrationForm;