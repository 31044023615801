import React, { useEffect, useState } from "react";
import { WEBSITE_URL } from "../Constant";
import {
  Container,
  Navbar,
  Image,
  Nav,
  Form,
  FormControl,
  Button,
  Tab,
  Tabs,
  Row,
  Col,
  Card,
  CardBody,
} from "react-bootstrap";
import { setUserDataAction } from "../actions/userdetailAction";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { API_URL, SUPPORT_URL } from "../Constant";
import modulecss from "../cssmodules/UserForm.module.css";
import swal from "@sweetalert/with-react";
import Select from 'react-select'
import axios from "axios";

function Wallet({userData, authData,}) {
/*End 29012022*/
const [imageState, setImageState] = useState();
const [imgName, setImgName] = useState(null);
const [transHistory, settransHistory] = useState([]);
const [vaultBalance, setvaultBalance] = useState([]);
const [val, setval] = useState(0);
const [Customername, setCustomername] = useState(null);
const [vaultCustomer, setvaultCustomer] = useState([{
    "gdv_account_id":"0000000000000000"
}]);
const [Percent, setPercent] = useState(null);
  
function headerLinkFun(linkType) {
    if (linkType == "logout") {
    sessionStorage.clear();
    window.location.href =
    WEBSITE_URL + "/" + "logout";
    }
}

const getCustomerDetails = async (data) => {
    const customers = await axios.get( API_URL + '/accounts/showAllCx/');
    const array = customers.data
    var current_customer = array.find(x => x.id === parseInt(data))
    const vault_customer = await axios.post( API_URL + '/points_system/GetCustomer/', {"customer_id":current_customer.id});
    setCustomername(current_customer.first_name + " " + current_customer.last_name)
    setvaultCustomer(vault_customer.data)
}

const getPercent = async () => {
  const percent = await axios.get( API_URL + '/points_system/AmountPoints/');
  setPercent(percent.data)
  
}

const fetchData = async () => {
    
  var url = window.location.href;
  var customer_id = url.substring(url.lastIndexOf('/') + 1);
  var request = {
      "customer_id":customer_id
  }
  const data = await axios.post( API_URL + '/points_system/GetCustomer/', request);
  var request2 = {
    "gdvt_account_id":data.data[0].id
  };
  setvaultBalance(data.data[0].vault_balance)
  getCustomerDetails(customer_id);
  var trans_array = []
  var trans_history = await axios.post( API_URL + '/points_system/vaultTransactionsDisplay/', request2);
  trans_array.push(trans_history.data)
  // function sortByKeyDesc(array, key) {
  //     return array.sort(function (a, b) {
  //         var x = a[key]; 
  //         var y = b[key];
  //         return ((x > y) ? -1 : ((x < y) ? 1 : 0));
  //     });
  // }
  var newarray = trans_history.data.sort(function(a, b) {
  return new Date(b.transaction_date) - new Date(a.transaction_date);
});
      settransHistory(trans_history.data)
    }

  useEffect(() => {
    
    setval(1)
    // if (localStorage.getItem("response") === null) {
    //   window.location.href = "/login";
    // }
    fetchData();
    getPercent();
},[]);

const get_date = (str) => {
    var date = new Date(str);
    var dt = date.getDate();
    if (dt < 10) {
      dt = '0' + dt;
    }
    return dt
}

const get_month = (str) => {
    var date = new Date(str);
    var month = date.getMonth()+1;
    if (month < 10) {
      month = '0' + month;
    }
    // return month
    return date.toLocaleString('en-US', {
        month: 'short',
      })
}


return (

<>
<div className="container wallet_container">   
    <Row className="main_viewCls">
    <Col md={4}>
      <div style={{"margin-top":"3rem"}}>
        <div className="wallet_card_div">
            <div style={{"margin-left":"40%", "padding": "70px 0"}}>
                <div>
                    <span className="username">{Customername}</span>
                </div>
                <div>
                    {vaultCustomer.map((x,i) => {
                        return(
                            <span className="member_id">{
                                x.gdv_id 
                                ? 
                                x.gdv_id.replace(/[^\dA-Z]/g, '').replace(/(.{4})/g, '$1 ').trim()
                                :
                                ""
                                }
                            </span>
                            )
                    })}
                </div>
                <div>
                    <span className="member_type">Premium</span>
                </div>
            </div>
        </div>

        <div className="balance_card">
            <div className="row">
                <div className="col-7 balance_card_title">
                    <i><img src="../images/new_images/balance-points-icon.png" /></i>
                    <span>Balance Points</span>
                </div>
                <div className="col-5 balance_card_value">
                    <span>{vaultBalance}</span>
                </div>
            </div>
        </div>
        <div className="balance_card">
            <div className="row">
                <div className="col-7 balance_card_title">
                    <i><img src="../images/new_images/balance-amounts-icon.png" /></i>
                    <span>Balance Amount</span>
                </div>
                <div className="col-5 balance_card_value">
                        {
                            <span>
                            <span className="rupee">₹ </span>
                            <span>{+ Math.round((vaultBalance * 10)/100)}</span>
                            </span>
                        }
                </div>
            </div>
        </div>
      </div>
    </Col>

    <Col md={8}>
        <div style={{}}>
            <p className="wallet_history_head">Wallet Transaction History</p>
        </div> 

        <div style={{ 
         }}>

        <Card.Body style={{"height":"40em", "overflow-y":"scroll"}}>                   
            {
            transHistory.length > 0 
            ? 
            transHistory.map((x,i) => {
                return(
                    <div>

                       <div>

                            {
                                x.debit == true 
                                ?
                                <div className="row history p-4 mb-4 bg-white red_left">
                                    <div className="col-1 date_box">
                                        <p className="day">{get_date(x.transaction_date)}</p>
                                        <p className="month">{get_month(x.transaction_date)}</p>
                                    </div>
                                    <div className="col-11">
                                        <span className="points_head">Redeemed Points</span>
                                        <span style={{    fontSize: "17",
                                                  padding: "7px",
                                                  color: "#d72413",
                                                  fontWeight: "600",
                                                  float: "right",                            
                                              }}>- {x.points}
                                        </span>

                                        <div className="booking_id">
                                            <span>Booking Id </span>
                                            <span>{x.booking_id}</span>
                                        </div>
                                </div>
                                </div>

                                :
                                <div className="row history p-4 mb-4 bg-white green_left">
                                    <div className="col-1 date_box">
                                        <p className="day">{get_date(x.transaction_date)}</p>
                                        <p className="month">{get_month(x.transaction_date)}</p>
                                    </div>
                                    <div className="col-11">
                                        <span className="points_head">Reward Points</span>
                                        <span style={{    fontSize: "17",
                                                  padding: "7px",
                                                  color: "#78ac09",
                                                  fontWeight: "600",
                                                  float: "right",                            
                                              }}>+ {x.points}
                                        </span>
                                        <div className="booking_id">
                                            <span>Booking Id </span>
                                            <span>{x.booking_id}</span>
                                        </div>
                                    </div>
                                </div>
                            }

                       </div>
                        
                      
                    </div>
                )
            })
            :
            <p><strong>No Transactions</strong></p>

        }

            </Card.Body>
      </div>
    </Col>
</Row>
</div>
</>

);

}

export default Wallet;
