import React, { useEffect, useState } from "react";
import { WEBSITE_URL } from "../Constant";
import {
  Container,
  Navbar,
  Image,
  Nav,
  Form,
  FormControl,
  Button,
  Tab,
  Tabs,
  Row,
  Col,
  Card,
  CardBody,
} from "react-bootstrap";
import { setUserDataAction } from "../actions/userdetailAction";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { API_URL, SUPPORT_URL } from "../Constant";
import modulecss from "../cssmodules/UserForm.module.css";
import swal from "@sweetalert/with-react";
import Select from 'react-select'
import axios from "axios";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faPlane, faPowerOff} from '@fortawesome/free-solid-svg-icons';

function UserForm({
  userData,
  authData,
  token,
  phone_number,
  name,
  country_code,
  history,
}) {
  const [userDataState, setUserDataState] = useState({
    email: userData.email,
    phone_number: userData.phone_number,
    first_name: userData.first_name,
    last_name: userData.last_name,
    address_ft: userData.address_ft,
    address_sc: userData.address_sc,
    city: userData.city,
    state: userData.state,
    zip: userData.zip,
    gender: false,
    company_pan: userData.company_pan,
    company_reg_name: userData.company_reg_name,
    company_gstIn: userData.company_gstIn,
    company_gst_state: userData.company_gst_state,
    is_company: userData.is_company,
  });

  console.log("userData")
  console.log(userData)
  // if(userData.email != null and )
  const [imgStatee, setImgStatee] = useState('/home/goceleb/Pictures/photo.png')

  const [emailState, setEmailState] = useState(userData.email);
  const [countrycode, setCountryCode] = useState(userData.country_code);
  const [phoneState, setPhoneState] = useState(userData.phone_number);
  const [ftNameState, setFtNameState] = useState(userData.first_name);
  const [ltNameState, setLtNameState] = useState(userData.last_name);
  const [ftaddressState, setFtAdressState] = useState(userData.address_ft);
  const [staddressState, setStAdressState] = useState(userData.address_st);
  const [cityState, setCityState] = useState(userData.city);
  const [stateState, setStateState] = useState(userData.state);
  const [zipState, setZipState] = useState(userData.zipcode);
  /*29012022*/
  const [panNoState, setPanNoState] = useState(userData.company_pan);
  const [compRegNameState, setCompRegNameState] = useState(userData.company_reg_name);
  const [gstState, setGstState] = useState(userData.company_gst_state);
  const [gstInState, setGstInState] = useState(userData.company_gstIn);
  const [iscompanyState, setIsCompanyState] = useState(userData.iscompanyState);
  const [country, setCountry] = useState(userData.country);
 
  const [city, setCity] = useState(userData.city);
  /*End 29012022*/
  const [imageState, setImageState] = useState();
  const [imgName, setImgName] = useState(null);

  const [successFormState, setSuccessFormState] = useState(true);
  const [countryID, setCountryID] = useState(null);
  const [stateStateID, setStateStateID] = useState(null);
  const [cityID, setCityID] = useState(null);


  // image preview field ///
  const [selectedImage, setSelectedImage] = useState();
  //////////////////////////

  const imageChange = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      setSelectedImage(e.target.files[0]);
    }
  };

  const dispatch = useDispatch();

  const bookData = useSelector((state) => state.setUserDataStore);
  const { error, loading, bookingData } = bookData;

  // setting up percentage fields
  let fieldWithData = 0;
  let totalField = Object.keys(userData).length -2;
  let percentageData = "";

  for (let k in userData) {
  
    if (
      userData[k] !== "" &&
      userData[k] !== false &&
      userData[k] !== true &&
      userData[k] !== "None" &&
      k !== "country_code"
    ) {
      fieldWithData += 1;
    }
  }

  //percentage of data filled
  percentageData = Math.round(fieldWithData * (100 / totalField));
  // console.log(cnt);

  function headerLinkFun(linkType) {
    if (linkType == "logout") {
      sessionStorage.clear();
      window.location.href =
        WEBSITE_URL + "/" + "logout";
    }
  }
  function changeCountry(value){
    setCountry(value.label);
    setCountryID(value.value);
  }
  function changeCity(value){
    setCity(value.label);
    setCityID(value.value);
  }
  function changeState(value){
    setStateState(value.label);
    setStateStateID(value.value);
  }
  var countryconst = [];
  var cityconst = [];
  var stateconst = [];
  
   function onInputCountryChange(inputValue) {
       
       axios.get(API_URL + "/accounts" + "/get-countries-list/?initials="+inputValue)
      .then(res => {
        for(var i=0;i<res.data.length;i++){
         const found = countryconst.some(el => el.label === res.data[i].name);
         if(!found){
            countryconst.push( { value: res.data[i].mapping_id, label: res.data[i].name });
         }
        }
      })
   }
   function onInputStateChange(inputValue) {
       axios.post(API_URL + "/accounts" + "/get-states-list/?initials="+inputValue, { "id":countryID })
      .then(res => {
        for(var i=0;i<res.data.length;i++){
          const found = stateconst.some(el => el.label === res.data[i].name);
           if(!found){
              stateconst.push( { value: res.data[i].mapping_id, label: res.data[i].name });           
            }
        }
      })
   }
   function onInputCityChange(inputValue) {
       axios.post(API_URL + "/accounts" + "/get-cities-list/?initials="+inputValue, { "id":stateStateID })
      .then(res => {
        for(var i=0;i<res.data.length;i++){
          const found = cityconst.some(el => el.label === res.data[i].name);
          if(!found){
            cityconst.push( { value: res.data[i].mapping_id, label: res.data[i].name });
          }
          
        }
      })
   }
    
  const handleSubmit = (event) => {
    // console.log("FFFFFFFFFFFFFFFFFFFF")
    // console.log(stateState)
    event.preventDefault();
    debugger
    if(userData.address_ft != "" && ftaddressState == ""){
      alert("Fields cannot be set to empty");
      return;
    }
    if(userData.address_st != "" && staddressState == ""){
      alert("Fields cannot be set to empty");
      return;
    }
    if(userData.city != "" && cityState == ""){
      alert("Fields cannot be set to empty");
      return;
    }
    if(userData.country_code != "" && event.target[2].value == ""){
      alert("Fields cannot be set to empty");
      return;
    }
    if(userData.email != "" && emailState == ""){
      alert("Fields cannot be set to empty");
      return;
    }
    if(userData.first_name != "" && ftNameState == ""){
      swal("Fields cannot be set to empty");
      return;
    }
    if(userData.last_name != "" && ltNameState == ""){
      alert("Fields cannot be set to empty");
      return;
    }
    if(userData.phone_number != "" && phoneState == ""){
      alert("Fields cannot be set to empty");
      return;
    }
    if(userData.profile_img != "" && imageState == ""){
      alert("Fields cannot be set to empty");
      return;
    }
    if(userData.state != "" && stateState == ""){
      alert("Fields cannot be set to empty");
      return;
    }
    if(userData.city != "" && city == ""){
      alert("Fields cannot be set to empty");
      return;
    }
    if(userData.country != "" && country == ""){
      alert("Fields cannot be set to empty");
      return;
    }
    if(userData.zipcode != "" && zipState == ""){
      alert("Fields cannot be set to empty");
      return;
    }
    /*29012022*/
    if(userData.is_company == true){
        if(userData.company_pan != "" && panNoState == ""){
          alert("Fields cannot be set to empty");
          return;
        }
        if(userData.company_reg_name != "" && compRegNameState == ""){
          alert("Fields cannot be set to empty");
          return;
        }
        if(userData.company_gst_state != "" && gstState == ""){
          alert("Fields cannot be set to empty");
          return;
        }
        if(userData.company_gstIn != "" && gstInState == ""){
          alert("Fields cannot be set to empty");
          return;
        }
    }
    
    /*End 29012022*/
    let requestData = {
      profile_img: imageState,
      email: emailState,
      country_code: countrycode,
      phone_number: phoneState,
      first_name: ftNameState,
      last_name: ltNameState,
      address_ft: ftaddressState,
      address_st: staddressState,
      city: city,
      country:country,
      zipcode: zipState,
      gender: true,
      company_pan: panNoState,
      company_reg_name: compRegNameState,
      company_gst_state: gstState,
      company_gstIn: gstInState,
      is_company: iscompanyState,
    };

    

    const uploadData = new FormData();
    uploadData.append("profile_img", imageState);
    uploadData.append("email", emailState);
    uploadData.append("country_code", countrycode);
    uploadData.append("phone_number", phoneState);
    uploadData.append("first_name", ftNameState);
    uploadData.append("last_name", ltNameState);
    uploadData.append("address_ft", ftaddressState);
    uploadData.append("address_st", staddressState);
    uploadData.append("address_st", staddressState);
    uploadData.append("state", stateState);
    uploadData.append("zipcode", zipState);
    uploadData.append("company_pan", panNoState);
    uploadData.append("company_reg_name", compRegNameState);
    uploadData.append("company_gst_state", gstState);
    uploadData.append("company_gstIn", gstInState);
    uploadData.append("is_company", iscompanyState);
    uploadData.append("country", country);
    uploadData.append("city", city);







    // console.log("&&&&&&^^^^^^^^^^^^^^^^%%%%%%%%%%%$$$$$$$$$$$$$$$");
    // console.log(imageState);
    // console.log(uploadData);
    dispatch(setUserDataAction(uploadData));
    
  };
  var percentage = percentageData + "%";


  function setImagePreview(url_data){
    
    var file = this.refs.file.files[0];
    var reader = new FileReader();
    var url = reader.readAsDataURL(file);
    // document.getElementById("profileImg").src = ""
  }

  return (
    <div>
      <Row>
        <Form className="mt-5" onSubmit={handleSubmit}>
          <Row>
            <Col md={3}>
              <Card className={modulecss.cardCss} style={{ width: "18rem" }}>
                <Card.Body>
                  <div className="row p-2 imgCss" style={{ margin: "auto" }}>
                    <Image
                      id="profileImg"
                      src={userData.profile_img ? userData.profile_img.split("?")[0] : process.env.PUBLIC_URL + "/images/avatar.jpg"}
                      alt="GoHopTrip"
                      fluid
                    />
                  </div>
                  <Row className="mt-2">
                    <input
                      type="file"
                      className="form-control fileuploadCls"
                      onChange={(e) => {
                        e.preventDefault();
                        console.log(e.target.files[0]);
                        setImageState(e.target.files[0])
                        setImgName(e.target.files[0].name)

                        const [file] = e.target.files
                        if (file) {
                          document.getElementById('profileImg').src = URL.createObjectURL(file)
                        }
                      
                      }}

                      
                    />
                  </Row>
                  <Row
                    className={`${modulecss.optionCss} ${modulecss.iconCss} mt-4 ${modulecss.mrb}`}
                  >

                    {/* <i className="fa fa-user" aria-hidden="true"></i>{" "} */}
                    <p className="user_btn"><span><FontAwesomeIcon className="iconn" icon={faUser}/></span>
                    <span> My Profile</span></p>
                  </Row>
                  <div className="row optionCss iconCss mrb tripCls">
                    {/* <i className="fa fa-plane" aria-hidden="true"></i> */}
                    <p className="user_btn"><span><FontAwesomeIcon className="iconn" icon={faPlane}/></span>
                    <span><a
                      className="tripCls triptext"
                      href={`${SUPPORT_URL}/${authData.token}/${authData.phone_number}/${authData.name}/${authData.country_code}/ft`}
                    >

                        My Trips
                    </a></span></p>
                  </div>
                  <div className="row optionCss iconCss mrb hoverCls">

                    {/* <i className="fa fa-sign-out" aria-hidden="true"></i> */}
                    <p className="user_btn"><span><FontAwesomeIcon className="iconn" icon={faPowerOff}/></span>
                    <span
                      className=""
                      onClick={(e) => {
                        e.preventDefault();
                        headerLinkFun("logout");
                      }}
                    >
                      {" "}
                      Log out

                    </span></p>
                  </div>
                </Card.Body>
              </Card>
            </Col>
            <Col md={9}>
              <div className="card cardCss" style={{ padding: "41px" }}>
                <p className="headcss">Complete Your Profile</p>
                <div className="progress prgressbar">
                  <div
                    className="progress-bar prgress"
                    role="progressbar"
                    style={{ width: `${percentageData}%` }}
                    aria-valuenow="25"
                    aria-valuemin="0"
                    aria-valuemax="100"
                  >
                    {`${percentageData}%`}
                  </div>
                </div>
                <br />
                <br />

                <Row className="mb-3">
                  <Form.Group as={Col} controlId="formGridEmail">
                    <Form.Label>Email</Form.Label>
                    <Form.Control
                      type="email"
                      placeholder={userData.email}
                      value={emailState}
                      onChange={(e) => {
                        e.preventDefault();
                        setEmailState(e.target.value);
                      }}
                    />
                  </Form.Group>

    
                </Row>

                <Row>
                  <Col>   
                  
                    <Form.Label>First name</Form.Label>
                    <Form.Control
                      placeholder={userData.first_name}
                      value={ftNameState}
                      onChange={(e) => {
                        e.preventDefault();
                        setFtNameState(e.target.value);
                        
                      }}
                    />
                  </Col>


                  <Col className="lastnmdivCls">
                    <Form.Label>Last name</Form.Label>
                    <Form.Control
                      placeholder={userData.last_name}
                      value={ltNameState}
                      onChange={(e) => {
                        e.preventDefault();
                        setLtNameState(e.target.value);
                      }}
                    />
                  </Col>

                  
                </Row>

                  <br />
                  <Row>           
                      <Col md={3}>
                        <Form.Label>Code</Form.Label>
                        <Form.Select onChange={(e) => {
                            e.preventDefault();

                            setCountryCode(e.target.value);
                          }}
                          value={countrycode}
                          >
                          <option>+91</option>
                        </Form.Select>
                      </Col>

                      <Col className="mobnodivCls">
                        <Form.Label>Phone Number</Form.Label>
                        <Form.Control
                          value={phoneState}
                          placeholder={userData.phone_number}
                          onChange={(e) => {
                            e.preventDefault();
                            setPhoneState(e.target.value);
                            
                          }}
                        />
                      </Col>             
                  
                  </Row>                        

                <br />
                <Form.Group className="mb-3" controlId="formGridAddress1">
                  <Form.Label>Address</Form.Label>
                  <Form.Control
                    placeholder={userData.address_ft}
                    value={ftaddressState}
                    onChange={(e) => {
                      e.preventDefault();
                      setFtAdressState(e.target.value);
                    }}
                  />
                </Form.Group>

                <Form.Group className="mb-3" controlId="formGridAddress2">
                  <Form.Label>Address 2</Form.Label>
                  <Form.Control
                    placeholder={userData.address_st}
                    value={staddressState}
                    onChange={(e) => {
                      e.preventDefault();
                      setStAdressState(e.target.value);
                    }}
                  />
                </Form.Group>

                <Row className="mb-3">
                <Form.Group as={Col} controlId="formGridZip">
                    <Form.Label>Country</Form.Label>
                    <Select value={{ label: country, value: country }} required  onInputChange={onInputCountryChange} options={countryconst} onChange={value => changeCountry(value)}/>
                  </Form.Group>
                 
                  <Form.Group as={Col} controlId="formGridCity" className="statedivCls">
                    <Form.Label>State</Form.Label>
                    
                    <Select value={{ label: stateState, value: stateState }} required onInputChange={onInputStateChange} options={stateconst} onChange={value => changeState(value)}/>
                  </Form.Group>
                  
                </Row>

                <Row className="mb-3">
                  <Form.Group as={Col} controlId="formGridCity">
                    <Form.Label>City</Form.Label>
                    
                    <Select value={{ label: city, value: city }} options={cityconst} onInputChange={onInputCityChange} onChange={value => changeCity(value)}/>
                  </Form.Group>

                  <Form.Group as={Col} controlId="formGridZip"  className="postcodeCls">
                    <Form.Label>Postal code</Form.Label>
                    <Form.Control
                      placeholder={userData.zipcode}
                      value={zipState}
                      onChange={(e) => {
                        e.preventDefault();
                        setZipState(e.target.value);
                      }}
                    />
                    
                  </Form.Group>

                  
                </Row>
                
                <Row className="mb-3">
                  <Form.Group className={userData.is_company==false?'hide':''} as={Col} controlId="formGridPan">
                    <Form.Label>Pan No</Form.Label>
                    <Form.Control
                      placeholder={userData.company_pan}
                      value={panNoState}
                      onChange={(e) => {
                        e.preventDefault();
                        setPanNoState(e.target.value);
                        
                      }}
                    />
                  </Form.Group>

                  <Form.Group className={userData.is_company==false?'hide':''} as={Col} controlId="formGridComName">
                    <Form.Label>Company Regd. Name</Form.Label>
                    <Form.Control
                      placeholder={userData.company_reg_name}
                      value={compRegNameState}
                      onChange={(e) => {
                        e.preventDefault();
                        setCompRegNameState(e.target.value);
                      }}
                    />
                  </Form.Group>
                </Row>
                <Row className="mb-3">
                  <Form.Group className={userData.is_company==false?'hide':''} as={Col} controlId="formGridGstState">
                    <Form.Label>GST State</Form.Label>
                    <Form.Control
                      placeholder={userData.company_gst_state}
                      value={gstState}
                      onChange={(e) => {
                        e.preventDefault();
                        setGstState(e.target.value);
                        
                      }}
                    />
                  </Form.Group>

                  <Form.Group className={userData.is_company==false?'hide':''} as={Col} controlId="formGridGstIn">
                    <Form.Label>GSTIN</Form.Label>
                    <Form.Control
                      placeholder={userData.company_gstIn}
                      value={gstInState}
                      onChange={(e) => {
                        e.preventDefault();
                        setGstInState(e.target.value);
                      }}
                    />
                  </Form.Group>
                </Row>
                <Button className="savebtnCls"
                  variant="primary"
                  type="submit"
                  style={{ width: "30%" }}
                >
                  Save
                </Button>
              </div>
            </Col>
          </Row>
        </Form>
      </Row>
    </div>
  );
}

export default UserForm;
