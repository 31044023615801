import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Container,
  Navbar,
  Image,
  Nav,
  Form,
  FormControl,
  Button,
  Tab,
  Tabs,
  Card,
  Col,
  Row,
  ListGroup,
  Table,
  Modal,
  ButtonGroup,
  ToggleButton,
} from "react-bootstrap";
import {
  WEBSITE_URL,
  TRIPJACK,
  API_URL,
  CANCEL_ERROR_MESSAGE,
} from "../Constant";
import Loader from "react-loader-spinner";

import {
  cancelTicketAction,
  refundDetailAction,
} from "../actions/userdetailAction";

import swal from "sweetalert";

function CancelFlight({ passList, setCancelShow, bookingDetails }) {
  console.log("Boking delatislsssssssssssssssssssssssss")
  console.log(bookingDetails)
  const [bookingDetailss,setbookingDetailss] = useState(bookingDetails);
  debugger;
  var finalLst = [];
  var travellerArr = [];
  var refundChargesAmt = 0;
  var triptype = null;
  //  get booking detail from database (store)
  const bookingData = JSON.parse(window.localStorage.getItem("bookDetailsKey"));
  const [refundState, setRefundState] = useState(0);
  //settin up traveller array
  bookingData.itemInfos.AIR.travellerInfos.map((traveller_data, i) => {
    travellerArr.push({ fn: traveller_data.fN, ln: traveller_data.lN });
  });

  //setting up request for refund details for cancellation///////////////
  var src_code = bookingData.itemInfos.AIR.tripInfos[0].sI[0].da.cityCode;
  var lngth = bookingData.itemInfos.AIR.tripInfos[0].sI.length;
  console.log(src_code);
  var dest_code =
    bookingData.itemInfos.AIR.tripInfos[0].sI[lngth - 1].aa.cityCode;
  console.log(dest_code);
  var departureDate =
    bookingData.itemInfos.AIR.tripInfos[0].sI[0].dt.split("T")[0];

  const tripDict = {
    src: src_code,
    dest: dest_code,
    departureDate: departureDate,
    paxArr: travellerArr,
    Booking_id: bookingData.order.bookingId,
    tripType: JSON.parse(localStorage.getItem("tripType")),
    trip_id:'none'
  };

  const dispatch = useDispatch();
  // ////////////////////////

  const handleClose = () => {
    setCancelShow(false);
    localStorage.removeItem("passengerDict");
  };

  const refundDetail = useSelector((state) => state.RefundDetailStore);
  const { error, loading, refundData } = refundDetail;



  // console.log(refundData);
  if (refundData) {
    if (refundData.status.success == false) {

      // if (refundData.errors[0].errCode == "2553" && refundData.errors[0].message == "Amendment unable to be raised due to %s , Please contact support team") {
      //   swal(refundData.errors[0].details).then(function () {
      //     // handleClose();
      //   });
      // }
      // else if(refundData.errors[0].errCode == "2553"){
      //   swal("cancellation is not applicable for Gohoptrip special bookings").then(function () {
      //     handleClose();
      //   });
      // }
      // else{
      //   swal(refundData.errors[0].details).then(function () {
      //     handleClose();
      //   });
      // }
      // refundData.status.success = true;
    } else {
      refundChargesAmt = refundData.trips[0].amendmentInfo.ADULT.amendmentCharges;
      triptype = bookingDetails.tripType_sec;
    }
  }
  // if (refundData) {
  //   debugger;
  //   if (refundData.status.success == false) {
  //     swal(CANCEL_ERROR_MESSAGE).then(function () {
  //       handleClose();
  //     });
  //     refundData.status.success = true;
  //   }
  // }
  // console.log("OOOOOOOOOOOOOOOOOOOOO");
  // console.log(refundData.trips[0]);

  const setPassanger = (b_details, indx, checkStatus, apiType = TRIPJACK) => {
    debugger;
    // console.log(passengerDict)

    if (apiType == TRIPJACK) {
      // if checkbox is set to true
      if (checkStatus == true) {
        debugger;
        finalLst.push(b_details);
        // console.log(localStorage.getItem("passengerDict"))
        if (localStorage.getItem("passengerDict") === null) {
          var passengerDict = {};
          passengerDict[indx] = b_details;
          localStorage.setItem("passengerDict", JSON.stringify(passengerDict));
        } else {
          var passengerDict = {};
          passengerDict = JSON.parse(localStorage.getItem("passengerDict"));
          passengerDict[indx] = b_details;
          localStorage.setItem("passengerDict", JSON.stringify(passengerDict));
        }

        // setPassDict(passengerDict);
        // console.log(JSON.parse(localStorage.getItem("passengerDict")));
      }
      // if checkbox is set to falsee
      else {
        var passengerDict = {};
        passengerDict = JSON.parse(localStorage.getItem("passengerDict"));

        delete passengerDict[indx];
        localStorage.setItem("passengerDict", JSON.stringify(passengerDict));
      }
      console.log(passengerDict);
    }
  };

  const submitCancellation = (event) => {

    event.preventDefault();
    debugger;
    var cancelTicketReq = {
      src: src_code,
      dest: dest_code,
      departureDate: departureDate,
      paxArr: travellerArr,
      Booking_id: bookingData.order.bookingId,
      tripType: JSON.parse(localStorage.getItem("tripType")),
      trip_id:'none'
    };

    console.log("FFFFFFFFFFFFFFFFFFFF");
    console.log(JSON.parse(localStorage.getItem("passengerDict")));
    if (
      JSON.parse(localStorage.getItem("passengerDict")) == null ||
      Object.keys(JSON.parse(localStorage.getItem("passengerDict"))).length ===
        0
    ) {
      swal("Please select a passanger to proceed");
      return;
    } else {
      var travellerArr = [];

      for (const [key, value] of Object.entries(
        JSON.parse(localStorage.getItem("passengerDict"))
      )) {
        console.log(key, value);
        travellerArr.push({ fn: value.fN, ln: value.lN });
      }

      cancelTicketReq.paxArr = travellerArr;
      cancelTicketReq.contacts =bookingData.order.deliveryInfo.contacts[0].substring(1);
      cancelTicketReq.email = bookingData.order.deliveryInfo.emails[0];
      cancelTicketReq.cancelCharge = refundChargesAmt;
      cancelTicketReq.triptype = triptype;
      cancelTicketReq.trip_id = bookingDetailss.id;
      console.log(cancelTicketReq);
      console.log(bookingDetailss);
      

      dispatch(cancelTicketAction(cancelTicketReq));
    }
  };

  useEffect(() => {
    dispatch(refundDetailAction(tripDict, "cancel_detail"));
  }, []);

  if (refundData) {
    console.log("DDDDDDDDDDDDDDDDDd");
    console.log(refundData["trips"]);
    return (
      <Form onSubmit={submitCancellation}>
        <Modal.Header closeButton>
          <Modal.Title>Cancel Ticket</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col md={5}>
              <Row>
                <Col>
                  <ListGroup variant="flush">
                    <ListGroup.Item>
                      <strong>Passangers</strong>
                    </ListGroup.Item>

                    {passList?.map((pass, i) => {
                      debugger;

                      return (
                        <ListGroup.Item>
                          <input
                            type="checkbox"
                            className="pt-2"
                            id="topping1"
                            name="topping"
                            value={pass}
                            onClick={(e) => {
                              setPassanger(pass, i, e.target.checked);
                            }}
                          />
                          <span className="p-2">{pass.fN} {pass.lN}</span>
                        </ListGroup.Item>
                      );
                    })}
                    <ListGroup.Item></ListGroup.Item>
                  </ListGroup>
                </Col>
              </Row>
            </Col>
            <Col md={3}></Col>
            <Col>
              <ListGroup>
                <ListGroup.Item>
                  <strong>Fare Breakup</strong>
                </ListGroup.Item>
                <ListGroup.Item>
                  <Row>
                    <Col md={7}>amendment charges</Col>
                    <Col className="text-center">
                      {refundData["trips"]
                        ? refundData["trips"][0]["amendmentInfo"]["ADULT"][
                            "amendmentCharges"
                          ]
                        : ""}
                    </Col>
                  </Row>
                </ListGroup.Item>
                <ListGroup.Item>
                  <Row>
                    <Col md={7}>refund Amount</Col>
                    <Col className="text-center">
                      {refundData["trips"]
                        ? refundData["trips"][0]["amendmentInfo"]["ADULT"][
                            "refundAmount"
                          ]
                        : ""}
                    </Col>
                  </Row>
                </ListGroup.Item>
                <ListGroup.Item>
                  <Row>
                    <Col md={7}>
                      <strong>Total</strong>
                    </Col>
                    <Col className="text-center">
                      <strong>
                        {refundData["trips"]
                          ? refundData["trips"][0]["amendmentInfo"]["ADULT"][
                              "totalFare"
                            ]
                          : ""}
                      </strong>
                    </Col>
                  </Row>
                </ListGroup.Item>
              </ListGroup>
            </Col>
          </Row>

          <Row className="mt-4">
            <Col md={7}>
              <ListGroup.Item>
                <strong>Cancellation Policies</strong>
              </ListGroup.Item>
              <ListGroup.Item>
                <ul>
                  <li>https://gohoptrip.com/ will process refund within 72 only after receiving it from the concerned Airlines/hotel/transporter/other suppliers.</li>
                  <li>
                  https://gohoptrip.com/ will process the refund only after getting cancellation request/claim from the customer/travel agent/ corporate company through email.
                  </li>
                  <li>
                  Service Charge is not refundable on cancellation.  
                  </li>
                </ul>
              </ListGroup.Item>
            </Col>
          </Row>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <input
            type="submit"
            className="btn btn-primary"
            variant="primary"
            value="submit"
          />
        </Modal.Footer>
      </Form>
    );
  } else {
    return (
      <Loader
        type="ThreeDots"
        color="#6D42EF"
        height={200}
        width={100}
        style={{ marginLeft: "45%" }}
        className=""
        id="loaderId"
      />
    );
  }
}

export default CancelFlight;
