import React, { useState, useEffect } from "react";
import {
  Container,
  Navbar,
  Image,
  Nav,
  Form,
  FormControl,
  Button,
  Tab,
  Tabs,
} from "react-bootstrap";
import Upcoming from "../Screens/Upcoming";
import Completed from "../Screens/Completed";
import Cancelled from "../Screens/Cancelled";
import { useDispatch, useSelector } from "react-redux";
import { listUserFlights } from "../actions/userdetailAction";
import Loader from "./Loader";
import Header from "./Header";
import NavbarComp from "./NavbarComp";
import MarkupScreen from "../Screens/MarkupScreen";
import { getToken } from "../Constant";

function Content({ match, history }) {
  // accesing data from url

  const token = match.params.token;
  // const cookie_token = getToken;
  const phone_number = match.params.phone_number;
  const name = match.params.name;
  const country_code = match.params.country_code;
  var display_st = match.params.display_tp;

  localStorage.setItem("type", JSON.stringify(display_st));



  localStorage.setItem("display_st", display_st)
  let authData = { token: token, phone_number: phone_number, name: name,  country_code : country_code };
  sessionStorage.setItem("authData", JSON.stringify(authData));

  // console.log(token)
  // console.log(phone_number)
  // console.log(name)

  const userId = phone_number;

  const [login, setlogin] = useState("abcd");

  const dispatch = useDispatch();
  const flightList = useSelector((state) => state.fligtDetailList);
  const { error, loading, tripData } = flightList;
  // debugger;
  // console.log("$$$$$$$$$$$$")
  // console.log(tripData)
  // if (tripData) {
  //     console.log("iiiiiiiiiiiiii")
  //     console.log(tripData.cancelled);
  // }

  useEffect(() => {
    // list to be displayed for flight
    debugger;
    if(display_st == "ft")
    {
      var requestData = {"phone_number":phone_number, "country_code":country_code, "reqestType":'POST'} ;
      var urlData = "/cancel-tripjack/oneway/";
    }

    // list to be displayed for hotel
    else if(display_st == "ht")
    {
      console.log("in hotel")
      console.log(phone_number)
      var requestData = {"phone_number":phone_number, "country_code":country_code, "reqestType":'POST'} ;
      var urlData = "/cancel-tripjack/get-hotel-data/";
    }
    else if(display_st == "bus"){
      console.log("in bus")
      console.log(phone_number)
      var requestData = {"phone_number":phone_number, "country_code":country_code, "reqestType":'POST'} ;
      var urlData = "/bus/get_bus_book_data/";
    }
    dispatch(listUserFlights(requestData, urlData));
    
  }, [dispatch]);

  if (true) {
    console.log("herere I am")
    return (
      <div>
          <Header token={token} phone_number={phone_number} nameVal={name} country_code={country_code}/>
          
        <div className="jumbotron1">
        <NavbarComp token={token} phone_number={phone_number} nameVal={name} country_code={country_code}/>
        <br/>
          <Container>
            <div className="card mt-1 mb-5">
              {loading || tripData == undefined ? (
                <Loader />
              ) : (
                <Tabs
                  defaultActiveKey="profile"
                  id="uncontrolled-tab-example"
                  className="mb-3"
                >
                  <Tab eventKey="profile" title="My Bookings">
                    <Upcoming oneWay={tripData?.upcomming} type={display_st} />
                  </Tab>
                  <Tab eventKey="home" title="My Cancelled Bookings">
                    <Cancelled trips={tripData?.cancelled} type={display_st} />
                  </Tab>
                  <Tab eventKey="contact" title="My Completed Bookings">
                    <Completed trips={tripData?.completed} type={display_st}/>
                  </Tab>
                  {/* <Tab eventKey="markup" title="Manage markup">
                    <MarkupScreen/>
                  </Tab> */}
                </Tabs>
              )}
            </div>
          </Container>
        </div>
      </div>
    );
  } else {
    return <div></div>;
  }
}

export default Content;
