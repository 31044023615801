import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import JumbotronComp from '../components/JumbotronComp'
import { listUserFlights } from '../actions/userdetailAction'
import Header from '../components/Header'
import { Link as RouterLink, useNavigate, useParams } from 'react-router-dom';

function Dashboard({ match, history }) {

    const [login, setlogin] = useState("abcd")
    // testItm = localStorage.getItem('Name');

    

    const dispatch = useDispatch()

    // const userList = useSelector(state => state.userList)
    // const { loading, error, list } = userList

    useEffect(() => {
        if (login) {
            // console.log(JSON.parse(localStorage.getItem('roomArr')))
            // dispatch(listUserFlights())
        } else {
            // history.push('/login')
        }
    }, [dispatch])
    
    return (
        <div>
            
            <JumbotronComp/>     
        </div>
    )
}

export default Dashboard
