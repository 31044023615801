import React, { useState, useEffect,useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Form,
  Button,
  Card,
  Col,
  Row,
  ListGroup,
  Modal,
} from "react-bootstrap";
import { WEBSITE_URL, TRIPJACK, API_URL, TICKET_URL } from "../Constant";
import $ from 'jquery'; //manisha
import modulecss from "../cssmodules/Menubox.module.css";
import swal from "@sweetalert/with-react";
// import DownloadInvoice from "./DownloadInvoice";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { setDateChangeAction } from "../actions/userdetailAction";
// import AmendmentModel from "./AmendmentModel";
import Loader from "react-loader-spinner";
import CancelFlight from "./CancelFlight";
import CancelBus from "./CancelBus";

function Menubox({ type, bookingDetails }) {
  console.log("NNNNNNNNNNNNNNNNNNNNNNNNN")
  console.log(bookingDetails)
  var passangerList = [];
  // var passengerDict = {};
  // var testArr = [];
  var finalLst = [];
  //date change
  const [modalShow, setModalShow] = React.useState(false);
  const [show, setShow] = useState(false);
  const [cancelshow, setCancelShow] = useState(false);
  const [checked, setChecked] = useState(false);
  const [startDate, setStartDate] = useState(new Date());
  const [passList, setPassList] = useState([]);
  const [reason, setReason] = useState("");
  const [passDict, setPassDict] = useState({});
  const [dateChangeStatus, setDateChangeStatus] = useState("");
  const [loaderState, setLoaderState] = useState(false);

  const dispatch = useDispatch();
  debugger;
  var bookingDetailApi = "";

  // console.log("((((((((((((((((((((((((((((");
  // console.log(bookingDetails);

  //redirecting to ticket page
  function redirectToTicket(params) {
    if (params == "hotel") {
      window.open(
        WEBSITE_URL +
          "/" +
          "hotel_voucher?id=" +
          bookingDetails.Hotel_booking_id +
          "&at=t"
      );
    } else if (params == "flight") {
      window.open(
        WEBSITE_URL +
          "/" +
          "flight_ticket?id=" +
          bookingDetails.Flight_booking_id +
          "&at=t"
      );
    }
    else if (params == "bus") {
      window.open(
        TICKET_URL +
          "/" +
          "bus_ticket?bus_id=" +
          bookingDetails.bus_id +
          "&TraceId="+bookingDetails.trace_id+"&bookId="+bookingDetails.gohoptrip_booking_id
      );
    }
  }

  // date change functions modal open and close flight
  const handleClose = () => {
    setShow(false);
    // remove selected passagers once modal is close
    localStorage.removeItem("passengerDict");
  };


  const handleShow = () => setShow(true);

  //cancellation function
  const handleCancelModalClose = () => setCancelShow(false);

  const handleCancelModalShow = () => {
    setCancelShow(true);
  };

  const dateChangeUpdate = useSelector((state) => state.setDateChangeStore);
  const { error, loading, setuserData } = dateChangeUpdate;
  
  
  if(setuserData != undefined){
    console.log(setuserData);
  }

  
  // setting up passanger list for flight booking
  const setPassangerList = (b_details, checkStatus, apiType = TRIPJACK) => {
    console.log("b_details----------------------------------------------")
    console.log(b_details)
    if (bookingDetails.apiType == 'TRIPJACK') {
      var passangerDetail = b_details.itemInfos.AIR.travellerInfos;
      // console.log("GGGGGGGGGGGG")
      // console.log(b_details)
      for (var passenger in passangerDetail) {
        passangerList.push(passangerDetail[passenger]);
      }

      setPassList(passangerList);
    }
    else if (bookingDetails.apiType == 'TBO') {
      var passangerDetail = b_details.Response.FlightItinerary.Passenger;
      console.log("GGGGGGGGGGGG")
      console.log(b_details)
      //manisha
      
      /* Fix: Hide temporarily date change and cancel ticket option inside myTrips.
      By: Manisha Kumavat
      Date: 06-2-2022 */
      $(".hidefeature").addClass("hide")
      for (var passenger in passangerDetail) {
        passangerList.push(passangerDetail[passenger]);
      }

      setPassList(passangerList);
    }
  };

  // selecting passangers for date change flight
  const setPassanger = (b_details, indx, checkStatus, apiType = TRIPJACK) => {
    debugger;
    // console.log(passengerDict)

    // if (bookingDetails.apiType == 'TRIPJACK') {
      // if checkbox is set to true
      if (checkStatus == true) {
        debugger;
        finalLst.push(b_details);
        console.log(localStorage.getItem("passengerDict"))
        if (localStorage.getItem("passengerDict") === null) {
          var passengerDict = {};
          passengerDict[indx] = b_details;
          localStorage.setItem("passengerDict", JSON.stringify(passengerDict));
        } else {
          var passengerDict = {};
          passengerDict = JSON.parse(localStorage.getItem("passengerDict"));
          passengerDict[indx] = b_details;
          localStorage.setItem("passengerDict", JSON.stringify(passengerDict));
        }
      }
      // if checkbox is set to false
      else {
        var passengerDict = {};
        passengerDict = JSON.parse(localStorage.getItem("passengerDict"));

        delete passengerDict[indx];
        localStorage.setItem("passengerDict", JSON.stringify(passengerDict));
      }
      // console.log(passengerDict);
    // }
  };

  const submitDateChange = (event) => {
    var tripState
    
    debugger;
    if(bookingDetails.apiType == 'TRIPJACK'){
      tripState = JSON.parse(localStorage.getItem("bookDetailsKey")).order
      .status;
    }else if(bookingDetails.apiType == 'TBO'){
      tripState = JSON.parse(localStorage.getItem("bookDetailsKey")).Response.FlightItinerary.Status;
      tripState = tripState == 0 ? 'Failed' : tripState == 1 ? 'SUCCESS' : tripState == 2 ? 'NotSaved' : tripState == 3 ? 'NotCreated' : tripState == 4 ? 'NotAllowed': tripState == 5 ? 'InProgress' : tripState == 6 ? 'TicketeAlreadyCreated' : tripState == 8 ? 'PriceChanged' : tripState == 9 ? 'OtherError' : 'Failed' 
    }
    event.preventDefault();
    var passengerDict = null;
    passengerDict = JSON.parse(localStorage.getItem("passengerDict"));
    // console.log(passDict);
    debugger;
    var passList = [];
    // console.log(passengerDict);
    if (passengerDict == null) {
      swal("please select a passanger");
      return;
    } else {
      if (Object.keys(passengerDict).length === 0) {
        swal("please select a passanger");
        return;
      }
      else if(tripState != "SUCCESS"){    
          swal("Trip is still in pending state");
          return;
      }
      else {
        Object.keys(passengerDict).map((key, index) =>
          passList.push(passengerDict[key])
        );
      }
    }

    debugger;
    bookingDetailApi = JSON.parse(localStorage.getItem("bookDetailsKey"));
    var reqDict = {
      passengers: passList,
      reason: reason,
      selected_date: startDate,
      apiType: bookingDetails.apiType,
      email_id: bookingDetails.apiType == 'TRIPJACK' ? JSON.parse(localStorage.getItem("bookDetailsKey")).order
        .deliveryInfo.emails[0] : JSON.parse(localStorage.getItem("bookDetailsKey")).Response.FlightItinerary.Passenger[0].Email,
      booking_id: bookingDetails.apiType == 'TRIPJACK' ? bookingDetailApi.order.bookingId : JSON.parse(localStorage.getItem("bookDetailsKey")).Response.FlightItinerary.BookingId,
      bookingDetailApi: bookingDetailApi,
      trip_id:bookingDetails.id
    };
    // console.log(reqDict);

    dispatch(setDateChangeAction(reqDict));
    localStorage.removeItem("passengerDict");
    setLoaderState(true);
    
  };

  useEffect(() => {
    bookingDetailApi = JSON.parse(localStorage.getItem("bookDetailsKey"));
    if (type == "ft") {
      setPassangerList(bookingDetailApi);
    }
  }, [dispatch, dateChangeStatus]);

  if (type == "ft") {
    var tripState
    if(bookingDetails.apiType == 'TRIPJACK'){
      tripState = JSON.parse(localStorage.getItem("bookDetailsKey")).order
      .status;
    }else if(bookingDetails.apiType == 'TBO'){
      tripState = JSON.parse(localStorage.getItem("bookDetailsKey")).Response.FlightItinerary.Status;
      tripState = tripState == 0 ? 'Failed' : tripState == 1 ? 'SUCCESS' : tripState == 2 ? 'NotSaved' : tripState == 3 ? 'NotCreated' : tripState == 4 ? 'NotAllowed': tripState == 5 ? 'InProgress' : tripState == 6 ? 'TicketeAlreadyCreated' : tripState == 8 ? 'PriceChanged' : tripState == 9 ? 'OtherError' : 'Failed' 
    }
    return (
      <>
        {/* model for date change */}
        <Modal
          show={show}
          onHide={handleClose}
          backdrop="static"
          keyboard={false}
          size="md"
        >
          {loaderState ? (
            <Loader
              type="ThreeDots"
              color="#6D42EF"
              height={200}
              width={100}
              style={{ marginLeft: "40%" }}
              className=""
              id="loaderId"
            />
          ) : (
            <Form onSubmit={submitDateChange}>
              <Modal.Header closeButton>
                <Modal.Title>Date Change</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Row>
                  <Col md={5}>
                    <ListGroup variant="flush">
                      <ListGroup.Item>
                        <strong>Passangers</strong>
                      </ListGroup.Item>

                      {passList?.map((pass, i) => {
                        var passfN,passlN
                         if(bookingDetails.apiType == 'TBO'){
                          passfN = pass.FirstName
                          passlN = pass.LastName
                         }
                         else if(bookingDetails.apiType == 'TRIPJACK'){
                          passfN = pass.fN
                          passlN = pass.lN
                         }
                        debugger;
                        // console.log(pass);
                        if (pass.amd_type != 'CANCEL') {
                          return (
                            <ListGroup.Item>
                              <input
                                type="checkbox"
                                className="pt-2"
                                id="topping1"
                                name="topping"
                                value={pass}
                                onClick={(e) => {
                                  setPassanger(pass, i, e.target.checked);
                                }}
                              />
                              <span className="p-2">{`${passfN} ${passlN}`}</span>
                            </ListGroup.Item>
                          );
                        }
                      })}
                      <ListGroup.Item></ListGroup.Item>
                    </ListGroup>
                  </Col>
                </Row>
                <Row className="mt-5">
                  <Col md={8}>
                    <Form.Group className="mb-3" controlId="formBasicPassword">
                      <Form.Label>
                        <strong>Select Date</strong>
                      </Form.Label>
                      <DatePicker
                        selected={startDate}
                        className="form-control"
                        minDate={new Date()}
                        onChange={(date) => {
                          // e.preventDefault();
                          setStartDate(date);
                        }}
                      />
                    </Form.Group>

                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlTextarea1"
                    >
                      <Form.Label>
                        <strong>Reason for Date Change</strong>
                      </Form.Label>
                      <Form.Control
                        as="textarea"
                        onChange={(e) => {
                          e.preventDefault();
                          setReason(e.target.value);
                        }}
                        required
                        rows={3}
                      />
                    </Form.Group>
                  </Col>
                </Row>
              </Modal.Body>

              <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                  Close
                </Button>
                <input
                  type="submit"
                  className="btn btn-primary"
                  variant="primary"
                  value="submit"
                />
              </Modal.Footer>
            </Form>
          )}
        </Modal>

        {/* cancellation modal data flight*/}
        <Modal
          show={cancelshow}
          onHide={handleCancelModalClose}
          backdrop="static"
          keyboard={false}
          size="xl"
        >
          <CancelFlight passList={passList} setCancelShow={setCancelShow} bookingDetails={bookingDetails}/>
        </Modal>

        <div className={modulecss.mainDivCls}>
          <Row>
            <Col>
              <Card className="m-4">
                <div>
                  <ListGroup variant="flush">
                    <ListGroup.Item className="handCursor">
                      <Row>
                        <Col>
                          <strong className="headingCls3">
                            Handle Booking
                          </strong>
                        </Col>
                      </Row>
                    </ListGroup.Item>

                    <ListGroup.Item
                      className="handCursor"
                      onClick={(e) => {
                        e.preventDefault();
                        if (tripState == "SUCCESS") {
                          redirectToTicket("flight");
                        } else {
                          swal("Booking still in process");
                        }
                      }}
                    >
                      <Row>
                        <Col>
                          <span className="txtCls">View E-ticket</span>
                        </Col>
                      </Row>
                    </ListGroup.Item>


                    {/* <ListGroup.Item className="handCursor">
                      <Row>
                        <Col>
                          <DownloadInvoice
                            type={type}
                            bookingDetails={bookingDetails}
                          />
                        </Col>
                      </Row>
                    </ListGroup.Item> */}

                    {/* <ListGroup.Item className="handCursor">
                      <Row>
                        <Col>
                          <span className="txtCls">Email/SMS</span>
                        </Col>
                      </Row>
                    </ListGroup.Item> */}
                    <ListGroup.Item className="handCursor hidefeature">
                      <Row>
                        <Col>
                          <span
                            variant="primary"
                            className="txtCls"
                            onClick={handleShow}
                          >
                            Date Change
                          </span>
                        </Col>
                      </Row>
                    </ListGroup.Item>
                    <ListGroup.Item className="handCursor hidefeature">
                      <Row>
                        <Col>
                          <span
                            variant="primary"
                            className="txtCls"
                            onClick={handleCancelModalShow}
                          >
                            Cancel Ticket
                          </span>
                        </Col>
                      </Row>
                    </ListGroup.Item>
                  </ListGroup>
                 
                </div>
              </Card>
            </Col>
          </Row>
        </div>
      </>
    );
  } else if (type == "ht") {
    return (
      <div className={modulecss.mainDivCls}>
        <Row>
          <Col>
            <Card className="m-4">
              <div>
                <ListGroup variant="flush">
                  <ListGroup.Item>
                    <Row>
                      <Col>
                        <strong className="headingCls3">Handle Booking</strong>
                      </Col>
                    </Row>
                  </ListGroup.Item>
                  <ListGroup.Item
                    className="handCursor"
                    onClick={(e) => {
                      e.preventDefault();
                      redirectToTicket("hotel");
                    }}
                  >
                    <Row>
                      <Col>
                        <a>
                          <span className="txtCls">Download E-ticket</span>
                        </a>
                      </Col>
                    </Row>
                  </ListGroup.Item>

                  {/* <ListGroup.Item className="handCursor">
                    <Row>
                      <Col>
                        <DownloadInvoice
                          type={type}
                          bookingDetails={bookingDetails}
                        />
                      </Col>
                    </Row>
                  </ListGroup.Item> */}

                  {/* <ListGroup.Item className="handCursor">
                    <Row>
                      <Col>
                        <span className="txtCls">Email/SMS</span>
                      </Col>
                    </Row>
                  </ListGroup.Item> */}
                </ListGroup>
              </div>
            </Card>
          </Col>
        </Row>
      </div>
    );
  } else if (type == "bus") {
    var tripState = JSON.parse(localStorage.getItem("bookDetailsKey")).status;
    return (
      <>
        <Modal
          show={show}
          onHide={handleClose}
          backdrop="static"
          keyboard={false}
          size="md"
        >
          {loaderState ? (
            <Loader
              type="ThreeDots"
              color="#6D42EF"
              height={200}
              width={100}
              style={{ marginLeft: "40%" }}
              className=""
              id="loaderId"
            />
          ) : (
            <Form onSubmit={submitDateChange}>
              <Modal.Header closeButton>
                <Modal.Title>Date Change</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Row>
                  <Col md={5}>
                    <ListGroup variant="flush">
                      <ListGroup.Item>
                        <strong>Passangers</strong>
                      </ListGroup.Item>

                      {passList?.map((pass, i) => {
                        debugger;
                        // console.log(pass);
                        if (pass.amd_type == 'CANCEL') {
                          return (
                            <ListGroup.Item>
                              <input
                                type="checkbox"
                                className="pt-2"
                                id="topping1"
                                name="topping"
                                value={pass}
                                onClick={(e) => {
                                  setPassanger(pass, i, e.target.checked);
                                }}
                              />
                              <span className="p-2">{`${pass.fN} ${pass.lN}`}</span>
                            </ListGroup.Item>
                          );
                        }
                      })}
                      <ListGroup.Item></ListGroup.Item>
                    </ListGroup>
                  </Col>
                </Row>
                <Row className="mt-5">
                  <Col md={8}>
                    <Form.Group className="mb-3" controlId="formBasicPassword">
                      <Form.Label>
                        <strong>Select Date</strong>
                      </Form.Label>
                      <DatePicker
                        selected={startDate}
                        className="form-control"
                        minDate={new Date()}
                        onChange={(date) => {
                          // e.preventDefault();
                          setStartDate(date);
                        }}
                      />
                    </Form.Group>

                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlTextarea1"
                    >
                      <Form.Label>
                        <strong>Reason for Date Change</strong>
                      </Form.Label>
                      <Form.Control
                        as="textarea"
                        onChange={(e) => {
                          e.preventDefault();
                          setReason(e.target.value);
                        }}
                        required
                        rows={3}
                      />
                    </Form.Group>
                  </Col>
                </Row>
              </Modal.Body>

              <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                  Close
                </Button>
                <input
                  type="submit"
                  className="btn btn-primary"
                  variant="primary"
                  value="submit"
                />
              </Modal.Footer>
            </Form>
          )}
        </Modal>

        {/* cancellation modal data bus*/}
        <Modal
          show={cancelshow}
          onHide={handleCancelModalClose}
          backdrop="static"
          keyboard={false}
          size="xl"
        >
          <CancelBus passList={passList} setCancelShow={setCancelShow} />
        </Modal>

        <div className={modulecss.mainDivCls}>
          <Row>
            <Col>
              <Card className="m-4">
                <div>
                  <ListGroup variant="flush">
                    <ListGroup.Item className="handCursor">
                      <Row>
                        <Col>
                          <strong className="headingCls3">
                            Handle Booking
                          </strong>
                        </Col>
                      </Row>
                    </ListGroup.Item>

                    <ListGroup.Item
                      className="handCursor"
                      onClick={(e) => {
                        e.preventDefault();
                        if (tripState == "SUCCESS") {
                          redirectToTicket("bus");
                        } else {
                          swal("Booking still in process");
                        }
                      }}
                    >
                      <Row>
                        <Col>
                          <span className="txtCls">View E-ticket</span>
                        </Col>
                      </Row>
                    </ListGroup.Item>

                    <ListGroup.Item className="handCursor">
                      <Row>
                        <Col>
                          <span
                            variant="primary"
                            className="txtCls"
                            onClick={handleCancelModalShow}
                          >
                            Cancel Ticket
                          </span>
                        </Col>
                      </Row>
                    </ListGroup.Item>
                  </ListGroup>
             
                </div>
              </Card>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

export default Menubox;
