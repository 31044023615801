import 
{ 
    USER_DETAIL_REQUEST, 
    USER_DETAIL_SUCCESS,
    USER_DETAIL_FAILURE,

    REFUND_DETAILS_REQUEST,
    REFUND_DETAILS_SUCCESS,
    REFUND_DETAILS_FAILURE,

    BOOKING_DETAIL_REQUEST,
    BOOKING_DETAIL_SUCCESS,
    BOOKING_DETAIL_FAILURE,

    CANCEL_DETAIL_REQUEST,
    CANCEL_DETAIL_SUCCESS,
    CANCEL_DETAIL_FAILURE,

    USER_DATA_REQUEST,
    USER_DATA_SUCCESS,
    USER_DATA_FAILURE,

    DATE_CHANGE_REQUEST,
    DATE_CHANGE_SUCCESS,
    DATE_CHANGE_FAILURE
} 
from '../Constant'

export const fligtDetailReducer = (state = {}, action) => {
    switch (action.type) {
        case USER_DETAIL_REQUEST:
            // console.log("reducers si here")
            return {
                loading: true
            }

        case USER_DETAIL_SUCCESS:
            return {
                loading: false,
                success: true,
                tripData: action.payload
            }

        case USER_DETAIL_FAILURE:
            return {
                loading: false,
                error: action.payload
            }

        default:
            return state
    }
}



export const getBookingDetailReducer = (state = {}, action)=>{

    switch (action.type) {
        case BOOKING_DETAIL_REQUEST:
            // console.log("reducers si here")
            return {
                loading: true
            }

        case BOOKING_DETAIL_SUCCESS:
            return {
                loading: false,
                success: true,
                bookingData: action.payload
            }

        case BOOKING_DETAIL_FAILURE:
            return {
                loading: false,
                error: action.payload
            }

        default:
            return state
    }

}


export const refundDetailReducer = (state={},action)=>{
    switch(action.type){
        case REFUND_DETAILS_REQUEST:
            return{
                loading:true
            }
        
        case REFUND_DETAILS_SUCCESS:
            return {
                loading: false,
                success: true,
                refundData: action.payload
            }
        
        case REFUND_DETAILS_FAILURE:
            return {
                loading: false,
                error: action.payload
            }
        
        default:
            return state
    }
}


export const cancelBookingReducer = (state={},action)=>{
    switch(action.type){
        case CANCEL_DETAIL_REQUEST:
            return{
                loading_cancel:true
            }
        
        case CANCEL_DETAIL_SUCCESS:
            return {
                loading_cancel: false,
                success_cancel: true,
                cancelData: action.payload
            }
        
        case CANCEL_DETAIL_FAILURE:
            return {
                loading_cancel: false,
                error_cancel: action.payload
            }
        
        default:
            return state
    }
}



// get user data reducer
export const getUserDataReducer = (state={},action)=>{
    switch(action.type){
        case USER_DATA_REQUEST:
            return{
                loading:true
            }
        
        case USER_DATA_SUCCESS:
            return {
                loading: false,
                success: true,
                userData: action.payload
            }
        
        case USER_DATA_FAILURE:
            return {
                loading: false,
                error: action.payload
            }
        
        default:
            return state
    }
}
// get invoice data reducer
export const getInvoiceDataReducer = (state={},action)=>{
    switch(action.type){
        case USER_DATA_REQUEST:
            return{
                loading:true
            }
        
        case USER_DATA_SUCCESS:
            return {
                loading: false,
                success: true,
                userData: action.payload
            }
        
        case USER_DATA_FAILURE:
            return {
                loading: false,
                error: action.payload
            }
        
        default:
            return state
    }
}


// set user data reducer
export const setUserDataReducer = (state={},action)=>{
    switch(action.type){
        case USER_DATA_REQUEST:
            return{
                loading:true
            }
        
        case USER_DATA_SUCCESS:
            return {
                loading: false,
                success: true,
                setuserData: action.payload
            }
        
        case USER_DATA_FAILURE:
            return {
                loading: false,
                error: action.payload
            }
        
        default:
            return state
    }
}


// set date change reducer
export const setDateChangeReducer = (state={},action)=>{
    switch(action.type){
        case DATE_CHANGE_REQUEST:
            return{
                loading:true
            }
        
        case DATE_CHANGE_SUCCESS:
            return {
                loading: false,
                success: true,
                setuserData: action.payload
            }
        
        case DATE_CHANGE_FAILURE:
            return {
                loading: false,
                error: action.payload
            }
        
        default:
            return state
    }
}


// set date change reducer
export const updateDateReducer = (state={},action)=>{
    switch(action.type){
        case DATE_CHANGE_REQUEST:
            return{
                loading:true
            }
        
        case DATE_CHANGE_SUCCESS:
            window.location.reload();
            return {
                loading: false,
                success: true,
                setuserData: action.payload
            }
        
        case DATE_CHANGE_FAILURE:
            return {
                loading: false,
                error: action.payload
            }
        
        default:
            return state
    }
}