import React, { useState, useLayoutEffect } from "react";
import {
  Container,
  Navbar,
  Image,
  Nav,
  Form,
  FormControl,
  Button,
  Tab,
  Tabs,
  Row,
  Col,
  Table,
} from "react-bootstrap";
import Moment from "react-moment";
import { WEBSITE_URL } from "../Constant";
import modulecss from "../cssmodules/UserProfile.module.css";
import "../cssmodules/UserProfile.css";
import "../App.css";
import { useDispatch, useSelector } from "react-redux";
import { getUserDataAction } from "../actions/userdetailAction";
import NavbarComp from "../components/NavbarComp";
import Header from "../components/Header";
import BusinessRegisterationForm from "../components/BusinessRegisterationForm";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleExclamation, faRotateBack} from '@fortawesome/free-solid-svg-icons'

function BusinessRegisteration({ match, history }) {
 
    return (
        <div className="b2b_registration">
          <Header/>
          <div className="col-md-12 jumbotron1" style={{"height":"8em"}}>
            <div className="contCls container mb-5" style={{ marginTop:"7em", "background":"#fff" }}>
            </div>
            <br />
          </div>
        {/*add component here*/}
        <BusinessRegisterationForm/>
        </div>
      );
  
  }
  
  export default BusinessRegisteration;