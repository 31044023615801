import React from 'react'
import { Container, Navbar, Image, Nav, Form, FormControl, Button, Tab, Tabs, Card, Col, Row, ListGroup } from "react-bootstrap";
import modulecss from '../cssmodules/CancelFareBox.module.css'

function CancelFareBox({amendmentCharges, refundAmount, totalFare}) {
    console.log(amendmentCharges)
    return (
        <Row className={modulecss.mainDiv}>
            <Col>
                <Card className="m-4">
                    <Card.Body>
                        <ListGroup variant="flush">
                            <ListGroup.Item>
                                <Row>
                                    <Col>
                                        <span>amendment charges</span>
                                    </Col>
                                    <Col className={modulecss.alignCls}>
                                        <span>{amendmentCharges}</span>
                                    </Col>
                                </Row>
                            </ListGroup.Item>
                            <ListGroup.Item>
                                <Row>
                                    <Col>
                                        <span>refund Amount</span>
                                    </Col>
                                    <Col className={modulecss.alignCls}>
                                        <span>{refundAmount}</span>
                                    </Col>
                                </Row>
                            </ListGroup.Item>
                     
                            <ListGroup.Item>
                                <Row>
                                    <Col>
                                        <span className="subHeadCls">Total</span>
                                    </Col>
                                    <Col className={modulecss.alignCls}>
                                        <strong>{totalFare}</strong>
                                    </Col>
                                </Row>
                            </ListGroup.Item>
                            
                        </ListGroup>
                    </Card.Body>
                </Card>
            </Col>
        </Row>
    )
}

export default CancelFareBox
