// API URL CONSTANT

export const ONEWAY = 'ONEWAY'
export const ROUNDTRIP = 'ROUNDTRIP'
export const MULTICITY = 'MULTICITY'
export const CANCEL = 'CANCEL'
export const CANCEL_HOTEL = 'CANCEL_HOTEL'

export const USER_DETAIL_REQUEST = 'USER_DETAIL_REQUEST'
export const USER_DETAIL_SUCCESS = 'USER_DETAIL_SUCCESS'
export const USER_DETAIL_FAILURE = 'USER_DETAIL_FAILURE'
export const TRIPJACK = 'TRIPJACK'
export const ETRAV = 'ETRAV'
export const TBO = 'TBO'


export const REFUND_DETAILS_REQUEST = 'REFUND_DETAILS_REQUEST'
export const REFUND_DETAILS_SUCCESS = 'REFUND_DETAILS_SUCCESS'
export const REFUND_DETAILS_FAILURE = 'REFUND_DETAILS_FAILURE'

export const BOOKING_DETAIL_REQUEST = 'BOOKING_DETAIL_REQUEST'
export const BOOKING_DETAIL_SUCCESS = 'BOOKING_DETAIL_SUCCESS'
export const BOOKING_DETAIL_FAILURE = 'BOOKING_DETAIL_FAILURE'

export const CANCEL_DETAIL_REQUEST = 'BOOKING_DETAIL_REQUEST'
export const CANCEL_DETAIL_SUCCESS = 'BOOKING_DETAIL_SUCCESS'
export const CANCEL_DETAIL_FAILURE = 'BOOKING_DETAIL_FAILURE'

export const USER_DATA_REQUEST = 'USER_DATA_REQUEST'
export const USER_DATA_SUCCESS = 'USER_DATA_SUCCESS'
export const USER_DATA_FAILURE = 'USER_DATA_FAILURE'

export const DATE_CHANGE_REQUEST = 'DATE_CHANGE_REQUEST'
export const DATE_CHANGE_SUCCESS = 'DATE_CHANGE_SUCCESS'
export const DATE_CHANGE_FAILURE = 'DATE_CHANGE_FAILURE'

export const POST = 'POST'
export const GET = 'GET'
export const PUT = 'PUT'
export const SERVER = 'LOCAL'


export const getToken=()=>{
    var x = document.cookie;
    try{
        if(SERVER == "LOCAL"){
            let authData = JSON.parse(sessionStorage.getItem("authData"))
            return authData.token;
        }
        else{
            var y = x.split(';')[0];
            y = y.split("=")[1];
            var cookie_val = JSON.parse(y);
            return cookie_val.token;
        }
    }
    catch(err){
        return err;
    }
  }


  export const getAuthData=()=>{
    
    let x = document.cookie;
    try{
        if(SERVER == "LOCAL"){
            let authData = JSON.parse(sessionStorage.getItem("authData"))
            return authData;
        }
        else{
            let y = x.split(';')[0];
        
            y = y.split("=")[1];
            return JSON.parse(y);
        }
        
    }
    catch(err){
        return err;
    }
  }

  export function setTokenCookie() {
    
    let cname= "userInfo"
    let cvalue = {"country_code":"+91", "display_st":"ft", "is_support":true, "name":"shaswat", "phone_number":"9359832119", "token":"eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ0b2tlbl90eXBlIjoiYWNjZXNzIiwiZXhwIjoxNjUxMDM2NDI1LCJqdGkiOiIyMzAxZmQxYWZkODg0Njk5OTM0ODZkMjE1YzE3YjdhZCIsInVzZXJfaWQiOjF9.rHEMd_cmJCYKYUk5sMVK9XKYycJ88IH6R8fvbo9PbGQ"}
    let days=30
    cvalue = JSON.stringify(cvalue);
    var dt, expires;
    dt = new Date();
    dt.setTime(dt.getTime() + (days * 24 * 60 * 60 * 1000));
    expires = "; expires=" + dt.toGMTString();
    document.cookie = cname+"="+cvalue + expires + ';'
    // document.cookie = cname+"="+cvalue+expires+'; domain=example.com'
}

export const c_id = "c_id"

export const SUPPORT_NUMBER =  '+91 8988987777'

export const CANCEL_ERROR_MESSAGE = 'Due to some reason Amendment can not be raised, Please contact support team'+SUPPORT_NUMBER
// export const WEBSITE_URL = ""
// export const SUPPORT_URL = ""
// export const API_URL = ""



    // export const WEBSITE_URL = "http://localhost"
    // export const SUPPORT_URL = "http://localhost:3000"
    // export const API_URL = "http://127.0.0.1:8000"
    // export const TICKET_URL = WEBSITE_URL+"/gohoptrip"

// export const WEBSITE_URL = "https://staging.gohoptrip.com"
// export const SUPPORT_URL = "https://stagingsupport.gohoptrip.com"
// export const API_URL = "https://staginglib.gohoptrip.com"
// export const TICKET_URL = WEBSITE_URL

// export const WEBSITE_URL = "https://dev.gohoptrip.com"
// export const SUPPORT_URL = "https://devsupport.gohoptrip.com"
// export const API_URL = "https://devlib.gohoptrip.com"
// export const TICKET_URL = WEBSITE_URL

    
    export const WEBSITE_URL = "https://gohoptrip.com"
    export const SUPPORT_URL = "https://support.gohoptrip.com"
    export const API_URL = "https://lib.gohoptrip.com"
    export const TICKET_URL = WEBSITE_URL

