import React, { useState, useEffect } from "react";
import { Container, Navbar, Image } from "react-bootstrap";
import modulecss from "../cssmodules/JumbotronComp.module.css";
import NavbarComp from "./NavbarComp";
import Content from "./Content";
import UpcommingScreen from "../Screens/UpcommingScreen";
import { useDispatch, useSelector } from "react-redux";
import {
  HashRouter,
  BrowserRouter as Router,
  Route,
  Switch,
  Link,
} from "react-router-dom";
import CancelTicket from "./CancelTicket";
import UserProfile from "../Screens/UserProfile";
import WalletScreen from "../Screens/WalletScreen";
import BusinessRegisteration from "../Screens/BusinessRegisteration";
import Header from "./Header";
import { getToken, SERVER } from "../Constant";
// import CancelHotel from "./CancelHotel";
import DirectPay from './DirectPay';
function JumbotronComp({ match, history }) {

  const dispatch = useDispatch();

  // useEffect(() => {
  //   dispatch(

  //     )
  //   )
  // }, [])

  return (
    <div>
      {/* <Header /> */}
      <div>
        {/* <NavbarComp /> */}

        <Router>
        {SERVER=="LOCAL"?<Route
            exact
            path="/:token/:phone_number/:name/:country_code/:display_tp"
            component={Content}
            exact
          />:<Route
          exact
          path="/:phone_number/:name/:country_code/:display_tp"
          component={Content}
          exact
        />}
          
          {/* <Route
            exact
            path="/:phone_number/:name/:country_code/:display_tp"
            component={Content}
            exact
          /> */}
          <Route path="/about" component={UpcommingScreen} />
          <Route path="/cancel" component={CancelTicket} />
          <Route path="/wallet" component={WalletScreen} />
          <Route path="/business_registeration_form" component={BusinessRegisteration} />
          
          {SERVER=="LOCAL"?<Route
            path="/profile/:token/:phone_number/:name/:country_code"
            component={UserProfile}
          />:<Route
          path="/profile/:phone_number/:name/:country_code"
          component={UserProfile}
        />}
          <Route path="/pay/:id" component={DirectPay} />
        </Router>
      </div>
    </div>
  );
}

export default JumbotronComp;
