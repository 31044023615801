import { createStore, combineReducers, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import { fligtDetailReducer, getBookingDetailReducer, refundDetailReducer, cancelBookingReducer, getUserDataReducer, setUserDataReducer, setDateChangeReducer,getInvoiceDataReducer } from './reducers/userDetailReducer'

const reducer = combineReducers({
    fligtDetailList : fligtDetailReducer,
    RefundDetailStore : refundDetailReducer,
    getBookingDetailStore:getBookingDetailReducer,
    cancelBookingStore:cancelBookingReducer,
    getUserDataStore:getUserDataReducer,
    setUserDataStore:setUserDataReducer,
    setDateChangeStore:setDateChangeReducer,
    getInvoiceDataStore:getInvoiceDataReducer,
})

const initialState = {}

const middleware = [thunk]

const store = createStore(reducer, initialState, 
    composeWithDevTools(applyMiddleware(...middleware)))


export default store 