/*
Remove increment and decrement of numbers on scroll of input boxes which have type "number"
Author: Muskan Patel
Date: 06-03-2023
*/
/*--*/

export const DisableIncDecNumber = () => {
    var inputTypeNumbers = document.querySelectorAll("input[type=number]");
    for (var a = 0; a < inputTypeNumbers.length; a++) {
        inputTypeNumbers[a].onwheel = function (event) {
            event.target.blur();
        };
    }
}