import React from 'react';
import { Container, Navbar, Image, Nav, Form, FormControl, Button,Tab,Tabs } from "react-bootstrap";
import { LinkContainer } from 'react-router-bootstrap';
import { SUPPORT_URL } from '../Constant'

function NavbarComp({ match, token, phone_number, nameVal,country_code }) {

    var flight_url = SUPPORT_URL+"/"+token+"/"+phone_number+"/"+nameVal+"/"+country_code+"/ft";
    var hotel_url = SUPPORT_URL+"/"+token+"/"+phone_number+"/"+nameVal+"/"+country_code+"/ht";
    var bus_url = SUPPORT_URL+"/"+token+"/"+phone_number+"/"+nameVal+"/"+country_code+"/bus";


    return (
        <div>

            <div className="container">
                <div className="card">
                    <div className="text-center d-flex nhome-search-center">
                        <a href={flight_url} className="iconDiv" style={{ margin: "15px" }}>
                            <Image src={
                                process.env.PUBLIC_URL + "/images/new_images/flight.png"
                            }
                                alt="GoHopTrip"
                                fluid />
                            <p className="icnTxt"><a href={flight_url}>Flights</a></p>
                        </a>

                        <a href={hotel_url} className="iconDiv" style={{ margin: "15px" }}>
                            <Image src={
                                process.env.PUBLIC_URL + "/images/new_images/hotels.png"
                            }
                                alt="GoHopTrip"
                                fluid />
                            <p className="icnTxt"><a href={hotel_url}>Hotels</a></p>
                        </a>

                        <a href={bus_url} className="iconDiv" style={{ margin: "15px" }}>
                            <Image src={
                                process.env.PUBLIC_URL + "/images/new_images/bus.png"
                            }
                                alt="GoHopTrip"
                                fluid />
                            <p className="icnTxt">Buses</p>
                        </a>

                        <a href="#" className="iconDiv" style={{ margin: "15px" }}>
                            <Image src={
                                process.env.PUBLIC_URL + "/images/new_images/taxi.png"
                            }
                                alt="GoHopTrip"
                                fluid />
                            <p className="icnTxt">Cabs</p>
                        </a>

                    </div>
                </div>
                
            </div>
        </div>
    )
}

export default NavbarComp
