import React from "react";
import {
  Container,
  Navbar,
  Image,
  Nav,
  Form,
  FormControl,
  Button,
  Dropdown,
} from "react-bootstrap";
import headercss from "../cssmodules/Header.module.css";
import { WEBSITE_URL, SUPPORT_URL } from "../Constant";
import { useHistory } from "react-router-dom";
import { LinkContainer } from "react-router-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faPlane, faPowerOff} from '@fortawesome/free-solid-svg-icons';

function Header({ match, token, phone_number, nameVal, country_code }) {
  let name = "";
  let history = useHistory();
  let profileUrl =
    "/" + token + "/" + phone_number + "/" + nameVal + "/" + country_code;

  if (JSON.parse(sessionStorage.getItem("authData"))) {
    let authdata = JSON.parse(sessionStorage.getItem("authData"));
  }

  function headerLinkFun(linkType) {
    if (linkType == "logout") {
      sessionStorage.clear();
      window.location.href = WEBSITE_URL + "/" + "logout";
    } else if (linkType == "viewBookings") {
      window.location.href = WEBSITE_URL + "/" + "viewbookings";
    } else if (linkType == "viewHotelBookings") {
      window.location.href = WEBSITE_URL + "/" + "viewhotelbookings";
    }
  }

  return (
    <Container className="contCls">
      <Navbar bg="" expand="lg" className="navbarCls">
        <Container fluid>
          <Navbar.Brand href={WEBSITE_URL}>
            <Image
              src={
                process.env.PUBLIC_URL + "/images/new_images/Gohoptriplogo.png"
              }
              alt="GoHopTrip"
              fluid
            />
          </Navbar.Brand>

          <Navbar.Toggle aria-controls="navbarScroll" />
          <Navbar.Collapse id="navbarScroll" className="toggnavbarbtnCls">
            <Nav
              className="me-auto my-2 my-lg-0"
              style={{ maxHeight: "100px" }}
              navbarScroll
            ></Nav>

            <Navbar.Text className="justify-content-end">
              <Dropdown style={{ color: "white" }} className="popupCls">
                <Dropdown.Toggle variant="" id="dropdown-basic">
                  <span style={{ color: "black" }}>
                    {/* <i
                      className="fa fa-user"
                      style={{ paddingRight: "1.5em" }}
                      aria-hidden="true"
                    ></i>    */}
                    {/* comment */}
                    <FontAwesomeIcon aria-hidden="true"
                      style={{ paddingRight: "0.5em" }} icon={faUser}/>
                    {nameVal}
                  </span>
                </Dropdown.Toggle>

                <Dropdown.Menu style={{ fontWeight: "bold" }}>
                  <Dropdown.Item
                    href={`${SUPPORT_URL}/${token}/${phone_number}/${nameVal}/${country_code}/ft`}
                  >
                    {/* <i
                      class="fa fa-plane"
                      aria-hidden="true"
                      style={{ paddingRight: "0.5em" }}
                    ></i> */}
                    <FontAwesomeIcon aria-hidden="true"
                      style={{ paddingRight: "0.5em" }} icon={faPlane}/>
                    My Trips
                  </Dropdown.Item>

                  <Dropdown.Item
                    href={`${SUPPORT_URL}/profile/${token}/${phone_number}/${nameVal}/${country_code}/display_st:ft`}
                  >
                    {/* <i
                      class="fa fa-user"
                      aria-hidden="true"
                      style={{ paddingRight: "0.5em" }}
                    ></i> */}
                    <FontAwesomeIcon aria-hidden="true"
                      style={{ paddingRight: "0.9em" }} icon={faUser}/>
                    My Profile{" "}
                  </Dropdown.Item>

                  {/* <Dropdown.Item
                    onClick={(e) => {
                      e.preventDefault();
                      headerLinkFun("viewBookings");
                    }}
                  >
                    <i
                      class="fa fa-eye"
                      aria-hidden="true"
                      style={{ paddingRight: "0.5em" }}
                    ></i>
                    View Bookings
                  </Dropdown.Item>

                  <Dropdown.Item
                    onClick={(e) => {
                      e.preventDefault();
                      headerLinkFun("viewHotelBookings");
                    }}
                  >
                    <i
                      class="fa fa-eye"
                      aria-hidden="true"
                      style={{ paddingRight: "0.5em" }}
                    ></i>
                    View Hotel Bookings
                  </Dropdown.Item> */}

                  <Dropdown.Item
                    href="#"
                    onClick={(e) => {
                      e.preventDefault();
                      headerLinkFun("logout");
                    }}
                  >

                    {/* <i
                      class="fa fa-power-off"
                      aria-hidden="true"
                      style={{ paddingRight: "0.5em" }}
                    ></i> */}
                    <FontAwesomeIcon aria-hidden="true"
                      style={{ paddingRight: "0.9em" }} icon={faPowerOff}/>
                    Logout
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </Navbar.Text>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </Container>
  );
}

export default Header;
