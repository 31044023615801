import React, { useState, useLayoutEffect } from "react";
import {
  Container,
  Navbar,
  Image,
  Nav,
  Form,
  FormControl,
  Button,
  Tab,
  Tabs,
  Row,
  Col,
  Table,
} from "react-bootstrap";
import Moment from "react-moment";
import { WEBSITE_URL } from "../Constant";
import modulecss from "../cssmodules/UserProfile.module.css";
import "../cssmodules/UserProfile.css";
import "../App.css";
import { useDispatch, useSelector } from "react-redux";
import { getUserDataAction } from "../actions/userdetailAction";
import NavbarComp from "../components/NavbarComp";
import Header from "../components/Header";
import Wallet from "../components/Wallet";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleExclamation, faRotateBack} from '@fortawesome/free-solid-svg-icons'

function WalletScreen({ match, history }) {
 
    return (
        <div className="wallet_header">
          <Header/>
          <div className="col-md-12 jumbotron1" style={{"height":"8em"}}>
            <div className="row" style={{"padding": "20px 0"}}>
              <div className="wallet_header_cols col-9">
                <span className="Wallet_header Wallet_header1">
                <FontAwesomeIcon icon={faCircleExclamation}/>
                <span>You're now logged in to GoHopTrip Premium Membership</span>
                </span>
              </div>
              <div className="wallet_header_cols col-3">
                <a href={WEBSITE_URL} className="Wallet_header Wallet_header2" style={{"cursor":"pointer"}}>
                  <span>Back to Home</span>
                  <FontAwesomeIcon icon={faRotateBack}/>
                </a>
              </div>
            </div>
            {/* <NavbarComp /> */}
            <div className="contCls container mb-5" style={{ marginTop:"7em" }}>
            </div>
            <br />
          </div>
            <Wallet/>
        </div>
      );
  
  }
  
  export default WalletScreen;