import React from "react";
import {
  Container,
  Navbar,
  Image,
  Nav,
  Form,
  FormControl,
  Button,
  Dropdown,
} from "react-bootstrap";
import headercss from "../cssmodules/Header.module.css";
import { WEBSITE_URL, SUPPORT_URL } from "../Constant";
import { useHistory } from "react-router-dom";
import { LinkContainer } from "react-router-bootstrap";
//////////////////////////////////

function DPHeader({ match, token, phone_number, nameVal, country_code }) {
  let name = "";
  debugger;

  let history = useHistory();
  let profileUrl =
    "/" + token + "/" + phone_number + "/" + nameVal + "/" + country_code;

  if (JSON.parse(sessionStorage.getItem("authData"))) {
    let authdata = JSON.parse(sessionStorage.getItem("authData"));
  }

  function headerLinkFun(linkType) {
    if (linkType == "logout") {
      sessionStorage.clear();
      window.location.href = WEBSITE_URL + "/" + "logout";
    } else if (linkType == "viewBookings") {
      window.location.href = WEBSITE_URL + "/" + "viewbookings";
    } else if (linkType == "viewHotelBookings") {
      window.location.href = WEBSITE_URL + "/" + "viewhotelbookings";
    }
  }

  return (
    <Container>
      <Navbar bg="" expand="lg">
        <Container fluid>
          <Navbar.Brand href={WEBSITE_URL}>
            <Image
              src={
                process.env.PUBLIC_URL + "/images/new_images/Gohoptriplogo.png"
              }
              alt="GoHopTrip"
              fluid
            />
          </Navbar.Brand>

          <Navbar.Toggle aria-controls="navbarScroll" />
          <Navbar.Collapse id="navbarScroll">
            <Nav
              className="me-auto my-2 my-lg-0"
              style={{ maxHeight: "100px" }}
              navbarScroll
            ></Nav>

            <Navbar.Text className="justify-content-end">
              
            </Navbar.Text>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </Container>
  );
}

export default DPHeader;
