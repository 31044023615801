import React from "react";
import {
  Container,
  Navbar,
  Image,
  Nav,
  Form,
  FormControl,
  Button,
  Tab,
  Tabs,
  Row,
  Col,
  Table,
  InputGroup,
  ListGroup,
  Alert,
} from "react-bootstrap";
import Flightdetail from "./Flightdetail";
import Menubox from "./Menubox";
import FareBox from "./FareBox";
import { useEffect, useState } from "react";
import CancelFareBox from "./CancelFareBox";
import {
  TRIPJACK,
  ETRAV,
  REFUND_DETAILS_REQUEST,
  REFUND_DETAILS_SUCCESS,
  REFUND_DETAILS_FAILURE,
  SUPPORT_URL,
} from "../Constant";
import { useDispatch, useSelector } from "react-redux";
import {
  cancelTicketAction,
  refundDetailAction,
} from "../actions/userdetailAction";
import ButtonComp from "./ButtonComp";
import Header from "./Header";
import NavbarComp from "./NavbarComp";
import swal from "@sweetalert/with-react";

function CancelTicket({ match, history }) {
  // //////////////////////////////////////////////////////////////////////
  const bookingData = JSON.parse(window.localStorage.getItem("bookDetailsKey"));
  console.log("#########################@@@@@@@@@@");
  console.log(bookingData);

  var travellerArr = [];
  var cancel_travellerArr = [];

  // setting up travellers array
  bookingData.itemInfos.AIR.travellerInfos.map((traveller_data, i) => {
    travellerArr.push({ fn: traveller_data.fN, ln: traveller_data.lN });
  });

  var src_code = bookingData.itemInfos.AIR.tripInfos[0].sI[0].da.cityCode;
  var lngth = bookingData.itemInfos.AIR.tripInfos[0].sI.length;
  console.log(src_code);
  // console.log(lngth);
  var dest_code =
    bookingData.itemInfos.AIR.tripInfos[0].sI[lngth - 1].aa.cityCode;
  console.log(dest_code);
  var departureDate =
    bookingData.itemInfos.AIR.tripInfos[0].sI[0].dt.split("T")[0];

  const tripDict = {
    src: src_code,
    dest: dest_code,
    departureDate: departureDate,
    paxArr: travellerArr,
    Booking_id: bookingData.order.bookingId,
    tripType: JSON.parse(localStorage.getItem("tripType")),
  };

  // console.log("tripdict");
  // console.log(tripDict);
  // //////////////////////////////////////////////////////////////////////

  // var Departdate = bookingData.itemInfos.AIR.tripInfos[0].sI[0].at

  const [active, setActive] = useState(null);
  const [cancelArr, setCancelArr] = useState([]);
  const [errorstatus, setErrorstatus] = useState(false);
  const [back, setBack] = useState(false);
  const [continuee, setContinuee] = useState(false);

  let is_refundable = true;

  let passangerArr = [
    { id: 1, fn: "Priya", ln: "singh" },
    { id: 2, fn: "Shaswat", ln: "kumar" },
    { id: 3, fn: "Manas", ln: "kulkarni" },
  ];

  let cancelPassangerArr = [];

  const dispatch = useDispatch();

  // get refunddata from state
  const refundDetail = useSelector((state) => state.RefundDetailStore);
  const { error, loading, refundData } = refundDetail;
  // console.log("###########");
  // console.log(refundData);

  if (refundData) {
    if (refundData.status.success == false) {
      // refundData.status.success = true

      swal({
        buttons: {
          confirm: { text: "Cancel Booking", className: "swal-success-button" },
          button: { text: "back", className: "swal-success-button" },
        },

        // buttons: ["Oh noez!", "Aww yiss!"],

        type: "warning",
        content: (
          <div>
            <Row>
              <Col>
                <strong>Booking is not refundable.</strong>
                <br />
                <span>
                  Click ok if you still want to continue with cancellation
                </span>
              </Col>
            </Row>
          </div>
        ),
      }).then(function (obj) {
        console.log(obj);
        if (obj == true) {
          console.log("success");
        } else {
          console.log("back");
          history.goBack();
        }
      });
    }
  }

  // get cancellation data from stat4e
  const cancelDetail = useSelector((state) => state.cancelBookingStore);
  const { error_cancel, loading_cancel, cancelData } = cancelDetail;

  // console.log("@@@@@@@@@@@@@@");
  // console.log(cancelData);
  if (cancelData && cancelData.status == "FAILURE") {
    var authData = JSON.parse(sessionStorage.getItem("authData"));
    swal({
      buttons: {},
      content: <span>{cancelData.response.errors[0].message}</span>,
    }).then(function (isConfirm) {
      if (true) {
        window.location.href =
          SUPPORT_URL +
          "/" +
          authData.token +
          "/" +
          authData.phone_number +
          "/" +
          authData.name +
          "/" +
          authData.country_code +
          "/ft";
      } else {
      }
    });
  }

  //check if refund is available
  let amendmentCharges = "";
  let refundAmount = "";
  let totalFare = "";

  debugger;
  // Setting up refund data for filling fare refund box

  let trpTyp = JSON.parse(localStorage.getItem("trip_type"));

  if (refundData != undefined && refundData.status.success == true) {
    if (trpTyp == "ROUNDTRIP") {
      let tripWay = JSON.parse(localStorage.getItem("tripType_sec"));
      if (JSON.parse(localStorage.getItem("tripType_sec")) == "ONWARD") {
        amendmentCharges =
          refundData.trips[0].amendmentInfo.ADULT.amendmentCharges;
        refundAmount = refundData.trips[0].amendmentInfo.ADULT.refundAmount;
        totalFare = refundData.trips[0].amendmentInfo.ADULT.totalFare;
      } else {
        amendmentCharges =
          refundData.trips[1].amendmentInfo.ADULT.amendmentCharges;
        refundAmount = refundData.trips[1].amendmentInfo.ADULT.refundAmount;
        totalFare = refundData.trips[1].amendmentInfo.ADULT.totalFare;
      }
    } else if (trpTyp == "ONEWAY") {
      amendmentCharges =
        refundData.trips[0].amendmentInfo.ADULT.amendmentCharges;
      refundAmount = refundData.trips[0].amendmentInfo.ADULT.refundAmount;
      totalFare = refundData.trips[0].amendmentInfo.ADULT.totalFare;
    } else if (trpTyp == "MULTICITY") {
      if (JSON.parse(localStorage.getItem("tripType_sec")) == "FT") {
        amendmentCharges =
          refundData.trips[0].amendmentInfo.ADULT.amendmentCharges;
        refundAmount = refundData.trips[0].amendmentInfo.ADULT.refundAmount;
        totalFare = refundData.trips[0].amendmentInfo.ADULT.totalFare;
      } else if (JSON.parse(localStorage.getItem("tripType_sec")) == "SC") {
        amendmentCharges =
          refundData.trips[1].amendmentInfo.ADULT.amendmentCharges;
        refundAmount = refundData.trips[1].amendmentInfo.ADULT.refundAmount;
        totalFare = refundData.trips[1].amendmentInfo.ADULT.totalFare;
      } else if (JSON.parse(localStorage.getItem("tripType_sec")) == "TH") {
        amendmentCharges =
          refundData.trips[2].amendmentInfo.ADULT.amendmentCharges;
        refundAmount = refundData.trips[2].amendmentInfo.ADULT.refundAmount;
        totalFare = refundData.trips[2].amendmentInfo.ADULT.totalFare;
      }
    }
    // console.log(refundData.trips[0].amendmentInfo.ADULT.amendmentCharges)
  } else {
    amendmentCharges = 0;
    refundAmount = 0;
    totalFare = 0;
    is_refundable = false;
  }

  // console.log(refundData.trips[0].amendmentInfo.ADULT.refundAmount)
  // console.log(refundData.trips[0].amendmentInfo.ADULT.totalFare)

  useEffect(() => {
    dispatch(refundDetailAction(tripDict, "cancel_detail"));
  }, [dispatch]);

  const getPassangerId = (evt, passangerData, indx) => {
    console.log(indx);
    if (cancelArr.includes(indx)) {
      var index = cancelArr.indexOf(indx);
      if (index !== -1) {
        cancelArr.splice(index, 1);
      }

      console.log(cancelArr);
    } else {
      cancelArr.push(indx);
      console.log(cancelArr);
    }
  };

  const getCancellationData = () => {
    bookingData = JSON.parse(window.localStorage.getItem("Gohoptrip_search"));
  };

  const cancelTicketRequest = () => {
    debugger;
    console.log("cancelled");
    console.log(tripDict);

    if (cancelArr.length > 0) {
      cancelArr.map((passanger_indx, i) => {
        cancel_travellerArr.push(travellerArr[passanger_indx]);
      });
      // console.log(tripDict);
      tripDict.paxArr = cancel_travellerArr;
      console.log(tripDict);
      dispatch(cancelTicketAction(tripDict));
      tripDict.paxArr = cancel_travellerArr;
    } else {
      console.log("herere i am");
      // errorstatus =false;
      setErrorstatus(true);
    }
  };

  if (loading_cancel == true) {
    return (
      <div>
        <h1>Loading....</h1>
      </div>
    );
  } else {
  }

  if (refundData != undefined) {
    let userAuthData = JSON.parse(sessionStorage.getItem("authData"));
    console.log("cancelData");
    console.log(userAuthData);
    return (
      <div>
        <Header
          token={userAuthData.token}
          phone_number={userAuthData.phone_number}
          nameVal={userAuthData.name}
        />

        <div className="jumbotron1">
          <NavbarComp
            token={userAuthData.token}
            phone_number={userAuthData.phone_number}
            nameVal={userAuthData.name}
            country_code={userAuthData.country_code}
          />
          <br />
          <Container>
            <div className="card">
              <Tabs
                defaultActiveKey="profile"
                id="uncontrolled-tab-example"
                className="mb-5 "
              >
                <Tab
                  eventKey="home"
                  title="Upcomming"
                  className="tab-pane active"
                >
                  <Alert
                    variant="danger"
                    className={errorstatus == true ? "show" : "hide"}
                  >
                    Please select a passanger for cancellation
                  </Alert>
                  <Row>
                    <Col></Col>
                  </Row>
                  <Row>
                    <Col md={8} className="p-4 ">
                      {
                        <Container>
                          {travellerArr.map((passanger, indx) => {
                            return (
                              <>
                                <ListGroup variant="" style={{ width: "19em" }}>
                                  <ListGroup.Item>
                                    <Row>
                                      <Col md={1}>
                                        <input
                                          onChange={(evt) => {
                                            getPassangerId(
                                              evt,
                                              passanger,
                                              indx
                                            );
                                          }}
                                          type="checkbox"
                                        />
                                      </Col>
                                      <Col>
                                        <span>{passanger.fn}</span>
                                        <span> </span>
                                        <span>{passanger.ln}</span>
                                      </Col>
                                    </Row>
                                  </ListGroup.Item>
                                </ListGroup>
                              </>
                            );
                          })}
                          <br />
                          <ListGroup>
                            <ListGroup.Item style={{ color: "red" }}>
                              Amount shown as Refund is a Gross Refund,
                              Commission will be recalled over and above and
                              Refund Amount
                            </ListGroup.Item>
                            <ListGroup.Item style={{ color: "red" }}>
                              Cancellation within 2hrs of scheduled departure is
                              not permitted
                            </ListGroup.Item>
                          </ListGroup>
                        </Container>
                      }
                    </Col>

                    <Col>
                      <div class="d-flex flex-column">
                        <CancelFareBox
                          amendmentCharges={amendmentCharges}
                          refundAmount={refundAmount}
                          totalFare={totalFare}
                        />
                        <Button
                          className="mb-3"
                          onClick={(e) => {
                            e.preventDefault();
                            cancelTicketRequest();
                          }}
                          variant="outline-danger"
                          style={{ marginLeft: "1.5em", marginRight: "1.5em" }}
                        >
                          Cancel Booking
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </Tab>
              </Tabs>
            </div>
          </Container>
        </div>
      </div>
    );
  } else {
    return (
      <div>
        <Container></Container>
      </div>
    );
  }
}

export default CancelTicket;
