import React, { useState, useEffect, useLayoutEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Container,
  Navbar,
  Image,
  Nav,
  Form,
  FormControl,
  Button,
  Tab,
  Tabs,
  Card,
  Col,
  Row,
  ListGroup,
  Table,
  Modal,
  ButtonGroup,
  ToggleButton,
} from "react-bootstrap";
import {
  WEBSITE_URL,
  TRIPJACK,
  API_URL,
  CANCEL_ERROR_MESSAGE,
  CANCEL_DETAIL_REQUEST,
  CANCEL_DETAIL_SUCCESS,
  CANCEL_DETAIL_FAILURE,
  REFUND_DETAILS_SUCCESS,
  REFUND_DETAILS_REQUEST,
  REFUND_DETAILS_FAILURE,
  POST,
  GET,
  TBO,
  SUPPORT_URL,
  getToken,
  setTokenCookie,
  getAuthData,
} from "../Constant";
import Loader from "react-loader-spinner";

import {
  cancelTicketAction,
  genericApiCall,
} from "../actions/userdetailAction";
import Moment from "react-moment";
import swal from "sweetalert";
import axios from "axios";

function CancelBus({ setCancelShow }) {
  const dispatch = useDispatch();
  const [passlist, setPasslist] = useState(
    JSON.parse(window.localStorage.getItem("bookDetailsKey")).passangers
  );
  
  const [loader, setLoader] = useState(false);
  var finalLst = [];
  var travellerArr = [];
  var cancellation_policy_var = '';

  //  get booking detail from database (store)
  const cancelTripApi = async (requestData, urlData) => {
    debugger;
    setLoader(true)
    console.log(requestData);
    var URL_DATA = API_URL + urlData;
    axios.post(URL_DATA, requestData)
      .then(res => {
          let authData = getAuthData();
        if(res.status == 200){
        
          console.log(authData);

          swal("ticket cancelled successfully").then(function (isConfirm) {
           
            if (isConfirm) {
              window.location.href =
              SUPPORT_URL +
              "/" +
              authData.token +
              "/" +
              authData.phone_number +
              "/" +
              authData.name +
              "/" +
              authData.country_code +
              "/bus"
            } else {
            }
            
          });
          
        }
        else{
          swal({
            buttons: {},
            content: <span>{"Something went wrong"}</span>,
          }).then(function (isConfirm) {
            localStorage.removeItem("passengerDict");
            if (true) {
              window.location.href =
                SUPPORT_URL +
                "/" +
                authData.token +
                "/" +
                authData.phone_number +
                "/" +
                authData.name +
                "/" +
                authData.country_code +
                "/bus";
            } else {
            }
          });
        }
      })
    
  };


  const submitCancellation = (event) => {
    event.preventDefault();
    // console.log(Object.keys(JSON.parse(localStorage.getItem("passengerDict"))).length )
    // if(JSON.parse(localStorage.getItem("passengerDict")) == null || Object.keys(JSON.parse(localStorage.getItem("passengerDict"))).length === 0){
    //   swal("Please select a passanger to proceed")
    //   return
    // }
    // else if(Object.keys(JSON.parse(localStorage.getItem("passengerDict"))).length > 1){
    //   swal("cannot cancel more than one ticket at a time. Please select single passanger")
    // }
    var travellerArr = []

      // for (const [key, value] of Object.entries(JSON.parse(localStorage.getItem("passengerDict")))) {
      //   console.log(key, value);
      //   travellerArr.push(value)
      // }
      
      console.log(travellerArr)
      console.log(JSON.parse(localStorage.getItem("bookDetailsKey")).bus_id)
      let reqData = {"busId":JSON.parse(localStorage.getItem("bookDetailsKey")).bus_id, "trace_id":JSON.parse(localStorage.getItem("bookDetailsKey")).trace_id, 'cancelPolicy':refundData.GetBookingDetailResult.Itinerary.CancelPolicy}
    
      // dispatch(cancelTicketAction(cancelTicketReq));
      cancelTripApi(reqData, '/bus/bus_cancel/');
  };

  const refundDetail = useSelector((state) => {
    if (state.RefundDetailStore) {
      // setLoader(false);
      return state.RefundDetailStore;
    }
    return null;
  });

  const { error, loading, refundData } = refundDetail;
  console.log("buss refund datata");
  console.log(refundData);

  useEffect(() => {
    debugger;
  
    const bookingData = JSON.parse(
      window.localStorage.getItem("bookDetailsKey")
    );
    console.log(bookingData);
    let tokenId = "";

    if (JSON.parse(localStorage.getItem("tokenId")) != null) {
      tokenId = JSON.parse(localStorage.getItem("tokenId"));
    }

    var reqData = { BusId: bookingData.bus_id, TraceId: bookingData.trace_id };

    const busBookingDetailApi = dispatch(
      genericApiCall(
        reqData,
        "/bus/get_bus_book_detail/",
        REFUND_DETAILS_REQUEST,
        REFUND_DETAILS_SUCCESS,
        REFUND_DETAILS_FAILURE,
        POST
      )
    );
  }, [loader]);


// remove checkbox data on cancel modal
  const handleClose = () => {
    setCancelShow(false);
    localStorage.removeItem("passengerDict");
  };

// set passanger checkbox data
  const setPassanger = (b_details, indx, checkStatus, apiType = TBO) => {
    debugger;
    // console.log(passengerDict)

    if (apiType == TBO) {
      // if checkbox is set to true
      if (checkStatus == true) {
        debugger;
        finalLst.push(b_details);
        // console.log(localStorage.getItem("passengerDict"))
        if (localStorage.getItem("passengerDict") === null) {
          var passengerDict = {};
          passengerDict[indx] = b_details;
          localStorage.setItem("passengerDict", JSON.stringify(passengerDict));
        } else {
          var passengerDict = {};
          passengerDict = JSON.parse(localStorage.getItem("passengerDict"));
          passengerDict[indx] = b_details;
          localStorage.setItem("passengerDict", JSON.stringify(passengerDict));
        }

        // setPassDict(passengerDict);
        // console.log(JSON.parse(localStorage.getItem("passengerDict")));
      }
      // if checkbox is set to false
      else {
        var passengerDict = {};
        passengerDict = JSON.parse(localStorage.getItem("passengerDict"));

        delete passengerDict[indx];
        localStorage.setItem("passengerDict", JSON.stringify(passengerDict));
      }
      console.log(passengerDict);
    }
  };



  if (refundData && loader==false) {
    console.log("DDDDDDDDDDDDDDDDDd");
    console.log(refundData);
    let itenaroy = refundData.GetBookingDetailResult.Itinerary;
    let CancelPolicy = itenaroy.CancelPolicy;
    return (
      <Form onSubmit={submitCancellation}>
        <Modal.Header closeButton>
          <Modal.Title>Cancel Ticket</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
          
            <Col>
              <ListGroup>
                <ListGroup.Item>
                  <strong>Cancellation Charges</strong>
                </ListGroup.Item>
                <Table bordered hover size="sm">
                  <thead>
                    <tr>
              
                      <th style={{"textAlign":"center"}}><span >{"From "}</span></th>
                      <th style={{"textAlign":"center"}}><span style={{"marginLeft":"2em"}}>{" To "}</span></th>
                      <th style={{"textAlign":"center"}}><span style={{"marginLeft":"2em"}}>{" Charges "}</span></th>
            
                    </tr>
                  </thead>
                  {CancelPolicy?.map((policy, indx) => {
                    debugger;
                    return (
                      <tbody>
                        {policy.CancellationCharge?(
                          <tr>
                      
                          <td style={{"textAlign":"center"}}>
                            <span>
                              <Moment format="ddd, DD MMM YYYY hh:mm a">
                                {policy.FromDate}
                              </Moment>
                            </span>
                          </td>
                        
                          <td style={{"textAlign":"center"}}>
                            <span>
                              <Moment format="ddd, DD MMM YYYY hh:mm a">
                                {policy.ToDate}
                              </Moment>
                            </span>
                          </td>
                          <td style={{"textAlign":"center"}}>
                            <span style={{ margin: "0.5em" }}>
                              {policy.CancellationCharge + "%"}
                            </span>
                          </td>
                        </tr>
                        ):''}
                        
                      </tbody>

                    );
                  })}
                </Table>
              </ListGroup>
            </Col>
          </Row>

          <Row className="mt-4">
            <Col md={7}>
              <ListGroup.Item>
                <strong>Cancellation Policies</strong>
              </ListGroup.Item>
              <ListGroup.Item>
              <ul>
                  <li>Cancellation of this ticket is NOT allowed after bus departure time.</li>
                  <li>
                  Service charge will not be refunded on cancellation of ticket.
                  </li>
                  <li>
                  The bus operator cancelling the trip due to unavoidable reasons.  
                  </li>
                </ul>
              </ListGroup.Item>
            </Col>
          </Row>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <input
            type="submit"
            className="btn btn-primary"
            variant="primary"
            value="submit"
          />
        </Modal.Footer>
      </Form>
    );
  } else {
    return (
      <Loader
        type="ThreeDots"
        color="#6D42EF"
        height={200}
        width={100}
        style={{ marginLeft: "45%" }}
        className=""
        id="loaderId"
      />
    );
  }
}

export default CancelBus;
