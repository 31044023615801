import React from "react";
import modulecss from "../cssmodules/ButtonComp.module.css";
import {
  Container,
  Navbar,
  Image,
  Nav,
  Form,
  FormControl,
  Button,
  Tab,
  Tabs,
} from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { CANCEL,CANCEL_HOTEL } from "../Constant";

function ButtonComp({ flightIndx, triptype, redirectTyp, segment = 1, tripType_sec, bookingData }) {
  const c_id = localStorage.getItem("c_id");
    console.log(bookingData);
  let history = useHistory();

  if (redirectTyp == CANCEL) {
    return (
      <div>
        <Button
              // className="btnViewManage"
              variant="outline-danger"
              onClick={() => {
                history.push({
                  pathname: "/cancel",
                  state: {segment:segment, tripType_sec:tripType_sec},
                });
              }}
            >
              Cancel Booking
        </Button>
      </div>
    );
  }
  else if(redirectTyp == CANCEL_HOTEL){
    return (
      <div>
        <Button
              // className="btnViewManage"
              variant="outline-danger"
              onClick={() => {
                history.push({
                  pathname: "/cancel-hotel/",
                  state: {segment:segment},
                });
              }}
            >
              Cancel Booking
        </Button>
      </div>
    );
  }
  else {
    const redirect_url = "/about/" + triptype + "/" + flightIndx;
    console.log("manage btn");
    return (
      <div>
        <LinkContainer to={redirect_url}>
          <Button className={modulecss.btnViewManage} variant="primary">
            View & Manage Booking
          </Button>
        </LinkContainer>
      </div>
    );
  }
}

export default ButtonComp;
