import React, { useLayoutEffect, useState } from "react";
import {
  Container,
  Navbar,
  Image,
  Nav,
  Form,
  FormControl,
  Button,
  Tab,
  Tabs,
  Row,
  Col,
  Card,
  CardBody,
} from "react-bootstrap";
import ButtonComp from "../components/ButtonComp";
import Moment from "react-moment";
import { useHistory } from "react-router-dom";
import modulecss from "../cssmodules/UserProfile.module.css";
import "../cssmodules/UserProfile.css";
import "react-datepicker/dist/react-datepicker.css";
import { useDispatch, useSelector } from "react-redux";
import DatePicker from "react-datepicker";
import { getUserDataAction } from "../actions/userdetailAction";
import UserForm from "../components/UserForm";
import Header from "../components/Header";
import NavbarComp from "../components/NavbarComp";
import { getToken, SERVER } from "../Constant";

function UserProfile({ match, history }) {
  const [startDate, setStartDate] = useState(new Date());

  const [heroimage, setHeroimage] = useState(null);

  const token = match.params.token;
  const phone_number = match.params.phone_number;
  const name = match.params.name;
  const country_code = match.params.country_code;


  let authData = { token: token, phone_number: phone_number, name: name, country_code:country_code };
  sessionStorage.setItem("authData", JSON.stringify(authData));

  const dispatch = useDispatch();

  const userData = useSelector((state) => state.getUserDataStore);
 



  useLayoutEffect(() => {
    dispatch(
      getUserDataAction({
        phone_number: phone_number, country_code:country_code
      })
    );
  }, [dispatch])



  if (userData.userData && Object.keys(userData).length > 0) {
    return (
      <div>
        <Header token={token} phone_number={phone_number} nameVal={Object.keys(userData).length>0?userData.userData.response[0].first_name:name} country_code={country_code}/>
        <div className="col-md-12 jumbotron1">
          {/* <NavbarComp /> */}
          <div className="container mb-5">
            <Row className={modulecss.pHeading}> MY PROFILE</Row>
            <UserForm userData={userData.userData.response[0]} authData={authData}/>
          </div>
          <br />
        </div>
      </div>
    );
  } else {
    return <></>;
  }
}

export default UserProfile;
