import React from "react";
import {
  Container,
  Navbar,
  Image,
  Nav,
  Form,
  FormControl,
  Button,
  Tab,
  Tabs,
  Row,
  Col,
} from "react-bootstrap";
import ButtonComp from "../components/ButtonComp";
import Moment from "react-moment";
import { MULTICITY, ONEWAY, ROUNDTRIP } from "../Constant";
import { useHistory } from "react-router-dom";

function Upcoming({ oneWay, type }) {

  // console.log("%$$$$$$$$$");
  // console.log(oneWay);
  // console.log(multiCity);

  let history = useHistory();

  if (type == "ft" && oneWay != undefined && oneWay.length > 0) {
    return (
      <div className="p-3">
        <div className="card p-3 pt-5">
          <div className="row">
            <div className="col-md-1">
              <span>
                <Image
                  src={
                    process.env.PUBLIC_URL +
                    "/images/booking-summary/completed-empty.png"
                  }
                  alt="GoHopTrip"
                  fluid
                />
              </span>
            </div>
            <div className="col mrgnAutoCls">
              <span className="headingCls blockCls">
                Up-coming Flight trips
              </span>
            </div>
          </div>

          {/* LOOPING OVER ALL ONEWAY FLIGHTS */}

          {oneWay?.map((flight, i) => {
            var arrival_dt = flight.arrival_time.split("T")[0];
            var arrival_tm = flight.arrival_time.split("T")[1];

            var departure_dt = flight.departure_time.split("T")[0];
            var departure_tm = flight.departure_time.split("T")[1];

            return (
              <>
                <div
                  className="card mt-5"
                  style={{ backgroundColor: "#f5f5f5" }}
                >
                  <div className="p-4">
                    <Row>
                      <Col>
                        <div className="d-flex flex-row">
                          <div>
                            <span className="headingCls">
                              {flight.from_city}
                            </span>
                          </div>
                          <div className="" style={{ padding: "5px" }}>
                            <Image
                              src={process.env.PUBLIC_URL + "/images/arrow.png"}
                              alt="GoHopTrip"
                              fluid
                            />
                          </div>
                          <div>
                            <span className="headingCls">{flight.to_city}</span>
                          </div>
                        </div>
                      </Col>

                      <Col style={{ textAlign: "right" }}>
                        {/* <ButtonComp
                          flightIndx={i}
                          triptype={"oneway"}
                          redirectTyp={"about"}
                          bookingData={flight}
                        /> */}
                        <Button
                          className="btnViewManage"
                          variant="primary"
                          onClick={() => {
                            history.push({
                              pathname: "/about",
                              state: {
                                bookingDetail: flight,
                                triptype: ONEWAY,
                                tripType_sec: flight.tripType_sec,
                              },
                            });
                          }}
                        >
                          View & Manage Booking
                        </Button>
                      </Col>
                    </Row>

                    <div className="row">
                      <div className="col">
                        <span className="subDetails">Upcoming </span>
                        <span className="subDetails">{flight.trip_type} </span>
                        <span className="subDetails">Booking ID</span>
                        <span>-</span>
                        <span className="subDetails">
                          {flight.gohoptrip_booking_id}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="p-4">
                    <div className="row">
                      <div className="col">
                        <div className="d-flex flex-column">
                          <div className="pb-1" style={{ fontWeight: "bold" }}>
                            From
                          </div>
                          <div className="">
                            <span>
                              <Moment format="ddd, DD MMM">
                                {departure_dt}
                              </Moment>
                            </span>
                            <span>{` ${departure_tm}`}</span>
                          </div>
                          <div className="">{flight.from_airport}</div>
                        </div>
                      </div>
                      <div className="col">
                        <div className="d-flex flex-column">
                          <div className="pb-1" style={{ fontWeight: "bold" }}>
                            To
                          </div>
                          <div className="">
                            <span>
                              <Moment format="ddd, DD MMM">{arrival_dt}</Moment>
                            </span>
                            <span>{` ${arrival_tm}`}</span>
                          </div>
                          <div className="">{flight.to_airport}</div>
                        </div>
                      </div>
                      <div className="col">
                        <div className="d-flex flex-column">
                          {/* <div className="pb-1">tt</div> */}
                          <div className="">
                            <span style={{ fontWeight: "bold" }}>Flight:</span>
                            <span>{` ${flight.airline_data}`}</span>
                          </div>
                          {/* <div className="">Priya + 1</div> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            );
          })}
        </div>
      </div>
    );
  } else if (type == "ht" && oneWay != undefined && oneWay.length > 0) {
    return (
      <div className="p-3">
        <div className="card p-3 pt-5">
          <div className="row">
            <div className="col-md-1">
              <span>
                <Image
                  src={
                    process.env.PUBLIC_URL +
                    "/images/booking-summary/completed-empty.png"
                  }
                  alt="GoHopTrip"
                  fluid
                />
              </span>
            </div>
            <div className="col mrgnAutoCls">
              <span className="headingCls blockCls">
                Up-coming Hotel Bookings
              </span>
            </div>
          </div>

          {/* LOOPING OVER ALL ONEWAY FLIGHTS */}
          
          {oneWay?.map((hotel, i) => {
            // console.log("oneway");

            var check_in_date = hotel.check_in_date;
            var check_out_date = hotel.check_out_date;

            return (
              <>
                <div
                  className="card mt-5"
                  style={{ backgroundColor: "#f5f5f5" }}
                >
                  <div className="p-4">
                    <Row>
                      <Col>
                        <div className="d-flex flex-row">
                          <div>
                            <span className="headingCls">
                              {hotel.hotel_name}
                            </span>
                          </div>
                        </div>
                      </Col>

                      <Col style={{ textAlign: "right" }}>
                        {/* <ButtonComp
                            flightIndx={i}
                            triptype={"oneway"}
                            redirectTyp={"about"}
                            bookingData={flight}
                          /> */}
                        <Button
                          className="btnViewManage"
                          variant="primary"
                          onClick={() => {
                            history.push({
                              pathname: "/about",
                              state: { bookingDetail: hotel, triptype: ONEWAY },
                            });
                          }}
                        >
                          View & Manage Booking
                        </Button>
                      </Col>
                    </Row>

                    <div className="row">
                      <div className="col">
                        <span className="subDetails">Upcoming </span>

                        <span className="subDetails">Booking ID</span>
                        <span>-</span>
                        <span className="subDetails">
                          {hotel.gohoptrip_booking_id}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="p-4">
                    <div className="row">
                      <div className="col-md-2">
                        <div className="d-flex flex-column">
                          <div className="pb-1" style={{ fontWeight: "bold" }}>
                            From
                          </div>
                          <div className="">
                            <span>
                              <Moment format="ddd, DD MMM">
                                {check_in_date}
                              </Moment>
                            </span>
                          </div>
                          {/* <div className="">{flight.from_airport}</div> */}
                        </div>
                      </div>
                      <div className="col-md-2">
                        <div className="d-flex flex-column">
                          <div className="pb-1" style={{ fontWeight: "bold" }}>
                            To
                          </div>
                          <div className="">
                            <span>
                              <Moment format="ddd, DD MMM">
                                {check_out_date}
                              </Moment>
                            </span>
                          </div>
                          {/* <div className="">{flight.to_airport}</div> */}
                        </div>
                      </div>
                      <div className="col-md-2">
                        <div className="d-flex flex-column">
                          {/* <div className="pb-1">tt</div> */}
                          <div className="">
                            <span style={{ fontWeight: "bold" }}>City:</span>
                            <span>{`  ${hotel.city}`}</span>
                          </div>

                          {/* <div className="">Priya + 1</div> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            );
          })}
        </div>
      </div>
    );
  } else if (type == "bus" && oneWay != undefined && oneWay.length > 0) {
    return (
      <div className="p-3">
        {/* LOOPING OVER ALL ONEWAY FLIGHTS */}


            <>
              <div className="p-3">
                <div className="card p-3 pt-5">
                  <div className="row">
                    <div className="col-md-1">
                      <span>
                        <Image
                          src={
                            process.env.PUBLIC_URL +
                            "/images/booking-summary/completed-empty.png"
                          }
                          alt="GoHopTrip"
                          fluid
                        />
                      </span>
                    </div>
                    <div className="col mrgnAutoCls">
                      <span className="headingCls blockCls">
                        Up-coming Bus Trips
                      </span>
                    </div>
                  </div>


                  {oneWay?.map((bus, i) => {
                    // console.log("oneway");
                    // console.log(bus);
                    var arrival_dt = "";
                    var arrival_tm = "";

                var departure_dt = bus.departure_date.split("T")[0];
                var departure_tm = bus.departure_date.split("T")[0];


                    // console.log(arrival_dt);
                    // console.log(arrival_tm);
                    return (
                      <>
                        <div
                          className="card mt-5"
                          style={{ backgroundColor: "#f5f5f5" }}
                        >
                          <div className="p-4">
                            <Row>
                              <Col>
                                <div className="d-flex flex-row">
                                  <div>
                                    <span className="headingCls">
                                      {bus.from_city}
                                    </span>
                                  </div>
                                  <div className="pl-2 pr-2">
                                    <span className="material-icons-outlined">
                                      {" "}
                                      →{" "}
                                    </span>
                                  </div>
                                  <div>
                                    <span className="headingCls">
                                      {bus.to_city}
                                    </span>
                                  </div>
                                </div>
                              </Col>

                          <Col style={{ textAlign: "right" }}>
                            {/* <ButtonComp
                          flightIndx={i}
                          triptype={"oneway"}
                          redirectTyp={"about"}
                          bookingData={flight}
                        /> */}
                            <Button
                              className="btnViewManage"
                              variant="primary"
                              onClick={() => {
                                history.push({
                                  pathname: "/about",
                                  state: {
                                    bookingDetail: bus,
                                    triptype: ONEWAY,
                                  },
                                });
                              }}
                            >
                              View & Manage Booking
                            </Button>
                          </Col>
                        </Row>

                        <div className="row">
                            <div className="col">
                              <span className="subDetails">Upcoming </span>
                              <span className="subDetails">
                                {bus.trip_type}{" "}
                              </span>
                              <span className="subDetails">Booking ID</span>
                              <span>-</span>
                              <span className="subDetails">
                                {bus.gohoptrip_booking_id}
                              </span>
                            </div>
                        </div>
                      </div>
                    </div>
                    <div className="card">
                      <div className="p-4">
                        <div className="row">
                          <div className="col">
                            <div className="d-flex flex-column">
                              <div
                                className="pb-1"
                                style={{ fontWeight: "bold" }}
                              >
                                Travel Date:
                              </div>
                              <div className="">
                                <span>
                                  <Moment format="ddd, DD MMM YY">
                                    {departure_dt}
                                  </Moment>
                                </span>
                          
                              </div>
                              <div className=""><span><strong>From: </strong></span><span>{bus.boarding_point}</span></div>
                            </div>
                          </div>
                          {/* <div className="col">
                                <div className="d-flex flex-column">
                                  <div
                                    className="pb-1"
                                    style={{ fontWeight: "bold" }}
                                  >
                                    To
                                  </div>
                                  <div className="">
                                    <span>
                                      <Moment format="ddd, DD MMM">
                                        {arrival_dt}
                                      </Moment>
                                    </span>
                                    <span>{` ${arrival_tm}`}</span>
                                  </div>
                                  <div className="">{bus.dropping_point}</div>
                                </div>
                              </div> */}
                          <div className="col">
                            <div className="d-flex flex-column">
                              {/* <div className="pb-1">tt</div> */}
                              <div className="">
                                <span style={{ fontWeight: "bold" }}>PNR:</span>
                                <span>{` ${bus.operator_PNR}`}</span>
                              </div>
                              {/* <div className="">Priya + 1</div> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                );
              })}
            </div>
          </div>
        </>
      </div>
    );
  } else {
    return (
      <div className="p-3">
        <div className="card p-3 pt-5">
          <div className="row">
            <div className="col">
              <div className="container m-4" style={{ color: "#6D42EF" }}>
                <h3>No Bookings are present in this section</h3>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Upcoming;
