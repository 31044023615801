import { useEffect, useState, React } from "react";
import {
  Container,
  Navbar,
  Image,
  Nav,
  Form,
  FormControl,
  Button,
  Tab,
  Tabs,
  Card,
  Col,
  Row,
  CardBody,
  ListGroup,
  Table,
} from "react-bootstrap";
import ButtonComp from "./ButtonComp";
import {
  CANCEL,
  MULTICITY,
  ONEWAY,
  ROUNDTRIP,
  CANCEL_HOTEL,
  SUPPORT_URL
} from "../Constant";
import Moment from "react-moment";
import swal from "@sweetalert/with-react";
import { API_URL } from "../Constant";
import axios from "axios";
import Loader from "react-loader-spinner";
// import swal from "@sweetalert/with-react";

function HotelDetail({ bookingDetails, apiBookingData, loaderData }) {
  var loaderVar = false;
  const [loader, setLoader] = useState(false);

  var cancelPolicy = "";

  if (apiBookingData != undefined) {
    var cancelPolicy = apiBookingData.itemInfos.HOTEL.hInfo.ops[0].cnp;
  }

  function callCancelAPI(reqData) {
    debugger;
    loaderVar = true;
    setLoader(true)
    axios.post(API_URL+"/cancel-tripjack/cancel-hotel-booking/", reqData).then((res) => {

      if(res.data.status.success == true)
      {
        setLoader(false);
        swal({
          text: "Cancellation Successful",
          icon: "success",
        }).then(function (isConfirm) {
          var authData = JSON.parse(sessionStorage.getItem("authData"));
      window.location.href = SUPPORT_URL+"/"+authData.token+"/"+authData.phone_number+"/"+authData.name+"/"+authData.country_code+"/ft";
        });
      }
      else{
        setLoader(false);
        swal({
          text: "Cancellation failed",
          icon: "error",
        });
      }

      return res;
    });
    
  }

  function cancelTicket() {
    swal({
      cancelButtonText: "Csdsdancel",
      buttons: {
        confirm: { text: "Cancel Booking", className: "swal-success-button" },
        
      },

      type: "warning",
      content: (
        <div>
          <Row>
            <Col>
              <Table
                className={cancelPolicy.ifra == true ? "mt-4" : "mt-4"}
                striped
                bordered
                hover
                size="sm"
              >
                <thead>
                  <tr>
                    <th>From Date</th>
                    <th>To Date</th>
                    <th>Cancellation Charge</th>
                  </tr>
                </thead>
                <tbody>
                  {cancelPolicy.pd.map((policy, pindx) => {
                    var departure_date = policy.fdt.split("T")[0];
                    var arrival_date = policy.tdt.split("T")[0];

                    // console.log("policy");
                    // console.log(policy);
                    return (
                      <tr>
                        <td>
                          <Moment format="ddd, DD MMM YYYY">
                            {departure_date}
                          </Moment>
                        </td>
                        <td>
                          <Moment format="ddd, DD MMM YYYY">
                            {arrival_date}
                          </Moment>
                        </td>
                        <td>̥₹{policy.am}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </Col>
          </Row>
          <Row>
            <Col>
              <span style={{ color: "red" }}>
                Are you sure you want to cancel
              </span>
            </Col>
          </Row>
        </div>
      ),
    }).then(function (isConfirm) {
      if (isConfirm) {
        let reqData = { booking_id: bookingDetails.Hotel_booking_id, 'cancelPolicy':cancelPolicy };
        var data = callCancelAPI(reqData);
      } else {
      }
      // console.log("%%%%%%%");
      // console.log(data)

      // if(data != undefined)
      // {
      //   swal({
      //     text: "Cancellation Successful",
      //     icon: "success",
      //   });
      // }
    });
  }



  // Use effect is used for setting loader here
  useEffect(() => {


  }, [loader]);


// console.log("bookingDetails");
// console.log(bookingDetails);
  if (loader) {
    return (
      <Loader
        type="ThreeDots"
        color="#6D42EF"
        height={200}
        width={150}
        style={{"marginLeft":"50%"}}
        className=""
        id="loaderId"
      />
    );
  }
  else{
    return (
      <>
        <div className="detailClss" id="detailId">
          <Row>
            <Col>
              <Table striped bordered hover>
                <tbody>
                  <tr>
                    <td>Hotel Name:</td>
                    <td>{bookingDetails.hotel_name}</td>
                  </tr>
                  <tr>
                    <td>Hotel Room Type:</td>
                    <td>{bookingDetails.room_type}</td>
                  </tr>
                  <tr>
                    <td>Check In Data:</td>
                    <td>{bookingDetails.check_in_date}</td>
                  </tr>
                  <tr>
                    <td>Check Out Data:</td>
                    <td>{bookingDetails.check_out_date}</td>
                  </tr>
                  <tr>
                    <td>City:</td>
                    <td>{bookingDetails.city}</td>
                  </tr>
                  <tr>
                    <td>Address:</td>
                    <td>{apiBookingData.itemInfos.HOTEL.hInfo.ad.adr}</td>
                  </tr>
                  <tr>
                    <td>Refund Policy:</td>
                    <td>
                      {bookingDetails.refundable == true
                        ? "Refundable"
                        : "Non-refundable"}
                    </td>
                  </tr>
                  <tr>
                    <td>Amount:</td>
                    <td>{(Math.round(bookingDetails.total_amt) + Math.round(bookingDetails.service_charge) + Math.round(bookingDetails.gst_charge) + Math.round(bookingDetails.markup_amount)).toFixed(2)}
                    </td>
                  </tr>
                </tbody>
              </Table>
            </Col>
          </Row>
  
          <Row className="pt-3">
            <Col>
              <Button
                variant="outline-danger"
                value="cancel"
                onClick={(e) => {
                  e.preventDefault();
                  cancelTicket();
                }}
              >
                Cancel Booking
              </Button>
            </Col>
          </Row>
        </div>
      </>
    );
  }

}

export default HotelDetail;
