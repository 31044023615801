import { useEffect, useState, React } from "react";
import {
  Container,
  Navbar,
  Image,
  Nav,
  Form,
  FormControl,
  Button,
  Tab,
  Tabs,
  Card,
  Col,
  Row,
  CardBody,
  ListGroup,
  Table,
} from "react-bootstrap";
import ButtonComp from "./ButtonComp";
import {
  CANCEL,
  MULTICITY,
  ONEWAY,
  ROUNDTRIP,
  CANCEL_HOTEL,
  SUPPORT_URL,
} from "../Constant";
import Moment from "react-moment";
import swal from "@sweetalert/with-react";
import { API_URL } from "../Constant";
import axios from "axios";
import Loader from "react-loader-spinner";
import modulecss from "../cssmodules/BusDetail.module.css";

function BusDetail({ bookingDetails, apiBookingData, loaderData }) {
  // console.log("detaillllllllllllllll");
  // console.log(bookingDetails);
  var loaderVar = false;
  const [loader, setLoader] = useState(false);

  if (loader) {
    return (
      <Loader
        type="ThreeDots"
        color="#6D42EF"
        height={200}
        width={150}
        style={{ marginLeft: "50%" }}
        className=""
        id="loaderId"
      />
    );
  } else {
    return (
      <Card className={`${modulecss.mainCard} p-4`}>
        <Card.Body>
          <Row>
            <Col md={4}>
              <span>
                <h5>Review your Booking</h5>
              </span>
            </Col>
            <Col>
              <span className="subHeadCls">{`(${bookingDetails.from_city}`}</span>
              <span class="material-icons-outlined"> → </span>
              <span className="subHeadCls">{`${bookingDetails.to_city})`}</span>
            </Col>

            <Col md={4} style={{ justifyContent: "end" }}>
              <span>Booking Status: </span>
              <span
                className={
                    bookingDetails.status == "SUCCESS" ? "success_class" : "failure_class"
                }
              >
                <strong>{bookingDetails.status}</strong>
              </span>
            </Col>
          </Row>
          <Card className={`p-2 mt-3 ${modulecss.flightCard}`}>
            <Row className="">
              <Col style={{ margin: "auto", textAlign: "center" }}>
                <div class="d-flex flex-column bd-highlight mb-3 setImgMargin">
                  <div class="bd-highlight">
                    {/* <Image
                      src={`//static.tripjack.com/img/airlineLogo/${trip.fD.aI.code}.png`}
                    /> */}
                  </div>
                  <div class="bd-highlight">
                    <img src="https://gohoptrip-media-dev.s3.ap-south-1.amazonaws.com/bl_icon.png"/>
                  </div>
                  <div class=" bd-highlight">
                    <small><strong>{bookingDetails.bus_operator}</strong></small>
                  </div>
                </div>
              </Col>

              <Col style={{ margin: "auto", textAlign: "center" }}>
                <span className="sub_font" style={{ display: "block" }}>
                  {`Travel Date`}
                </span>
                <span style={{ display: "block" }}>
                  <Moment format="ddd, DD MMM YYYY">
                    {bookingDetails.departure_date.split("T")[0]}
                  </Moment>
                </span>
                {/* <span
                  className={modulecss.bottomTxt}
                  style={{ display: "block" }}
                >
                  {bookingDetails.from_city}
                </span> */}
 
              </Col>
              <Col style={{ margin: "auto", textAlign: "center" }}>
              <span className="sub_font" style={{ display: "block" }}>
                  {`PNR`}
                </span>
                <span style={{ display: "block" }}>
                  {bookingDetails.ticket_number}
                </span>
              </Col>
              <Col style={{ margin: "auto", textAlign: "center" }}>
                <span className="sub_font" style={{ display: "block" }}>
                  {`Booking Id`}
                </span>
                <span style={{ display: "block" }}>
                  {bookingDetails.gohoptrip_booking_id}
                </span>
                
              </Col>
            </Row>
          </Card>
          
        
        </Card.Body>
      </Card>
    );
  }
}

export default BusDetail;
