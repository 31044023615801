import {
  USER_DETAIL_REQUEST,
  USER_DETAIL_SUCCESS,
  USER_DETAIL_FAILURE,
  API_URL,
  TRIPJACK,
  ETRAV,
  BOOKING_DETAIL_REQUEST,
  BOOKING_DETAIL_SUCCESS,
  BOOKING_DETAIL_FAILURE,
  REFUND_DETAILS_REQUEST,
  REFUND_DETAILS_SUCCESS,
  REFUND_DETAILS_FAILURE,
  CANCEL_DETAIL_REQUEST,
  CANCEL_DETAIL_SUCCESS,
  CANCEL_DETAIL_FAILURE,
  USER_DATA_REQUEST,
  USER_DATA_SUCCESS,
  USER_DATA_FAILURE,

  DATE_CHANGE_REQUEST,
  DATE_CHANGE_SUCCESS,
  DATE_CHANGE_FAILURE,
  SUPPORT_URL,
  POST,
  GET,
  PUT,
  getToken,
  WEBSITE_URL
} from "../Constant";
import axios from "axios";

import { useHistory } from "react-router-dom";
import swal from "sweetalert";
import Loader from "../components/Loader";


var JsonWebtoken = getToken()

function logoutFun(){
  debugger;
  let x = document.cookie;
  let y = x.split(';')[0];
 
  var dt, expires;
  dt = new Date();
  dt.setTime(dt.getTime() + (-1 * 0 * 0 * 0 * 0));
  expires = "; expires=" + dt.toGMTString();
  document.cookie = y + expires + ';'
}

export const listUserFlights = (requestData, urlData) => async (dispatch) => {
  if (requestData != undefined) {
    localStorage.setItem("c_id", requestData.phone_number);
  }

  try {
    dispatch({ type: USER_DETAIL_REQUEST });
    debugger
     
    let JsonWebtoken = getToken()
    console.log(JsonWebtoken)
    // const API = "http://gohoptrip.com:8000/cancel-tripjack/oneway/"    //Server URL
    const API = API_URL + urlData; //Local URL

    // const token = JSON.parse(localStorage.getItem('userInfo')).access
    const userId = requestData;

    if(requestData.reqestType == 'GET'){
      const { data } = await axios.get(API, userId, { headers: {"Authorization" : `${JsonWebtoken}`} });
      dispatch({
        type: USER_DETAIL_SUCCESS,
        payload: data,
      });
    }
    else if(requestData.reqestType == 'POST'){
      var token = getToken()
      const { data } = await axios.post(API, userId, { headers: {"Authorization" : `${JsonWebtoken}`} });
      console.log(data)
      dispatch({
        type: USER_DETAIL_SUCCESS,
        payload: data,
      });
    }

    
  } catch (error) {
    // if(error.response.status == 401){
    //   logoutFun()
    //   console.log(WEBSITE_URL+'logout/')
    //   window.location.href = WEBSITE_URL+'logout/';
    // }
    console.log(error.response)
    dispatch({
      type: USER_DETAIL_FAILURE,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};

// export const userLogin = (id, qty) => async (dispatch, getState) => {
//     const { data } = await axios.get(`/api/products/${id}`)

//     dispatch({
//         type: CART_ADD_ITEM,
//         payload: {
//             product: data._id,
//             name: data.name,
//             image: data.image,
//             price: data.price,
//             countInStock: data.countInStock,
//             qty
//         }
//     })
//     localStorage.setItem('cartItems', JSON.stringify(getState().cart.cartItems))
// }

export const getBookingDetailAction =
  (reqData, ApiTyp, urlData) => async (dispatch) => {
    try {
      dispatch({ type: BOOKING_DETAIL_REQUEST });

      const API = API_URL + urlData;
      console.log(API)
      // const token = JSON.parse(localStorage.getItem('userInfo')).access
      // const reqData = reqData;
      if(reqData.type == 'bus'){
        localStorage.setItem("bookDetailsKey", JSON.stringify(reqData.bookingdata));
        dispatch({
          type: BOOKING_DETAIL_SUCCESS,
          payload: reqData.bookingdata,
        });
      }
      else{
        var token = getToken()
        const { data } = await axios.post(API, reqData, { headers: {"Authorization" : `${JsonWebtoken}`} });
        console.log("data------------------------------------------------------>")
        console.log(data)
  
        localStorage.setItem("bookDetailsKey", JSON.stringify(data));
  
        dispatch({
          type: BOOKING_DETAIL_SUCCESS,
          payload: data,
        });
      }


    } catch (error) {
        if(error.response.status == 401){
          logoutFun()
          console.log(WEBSITE_URL+'logout/')
          window.location.href = WEBSITE_URL+'logout/';
        }
      dispatch({
        type: BOOKING_DETAIL_FAILURE,
        payload:
          error.response && error.response.data.detail
            ? error.response.data.detail
            : error.message,
      });
    }
  };

export const refundDetailAction =
  (cancelData, URL_DATA) => async (dispatch) => {
    try {
      dispatch({ type: REFUND_DETAILS_REQUEST });
      debugger
      const API = API_URL + "/cancel-tripjack/" + URL_DATA + "/";
      const reqdata = cancelData;
      var token = getToken()
      const { data } = await axios.post(API, reqdata, { headers: {"Authorization" : `${JsonWebtoken}`} });
 

      dispatch({
        type: REFUND_DETAILS_SUCCESS,
        payload: data,
      });
    } catch (error) {
      if(error.response.status == 401){
        logoutFun()
        console.log(WEBSITE_URL+'logout/')
        window.location.href = WEBSITE_URL+'logout/';
      }
      dispatch({
        type: REFUND_DETAILS_FAILURE,
        payload:
          error.response && error.response.data.detail
            ? error.response.data.detail
            : error.message,
      });
    }
  };

export const cancelTicketAction = (bookDict) => async (dispatch) => {
  debugger;
  try {
    dispatch({ type: CANCEL_DETAIL_REQUEST });
    console.log("loader element")
    console.log(document.getElementById("loaderId"))
    const API = API_URL + "/cancel-tripjack/cancel_ticket/";
    const reqdata = bookDict;
    debugger
    
    const { data } = await axios.post(API, reqdata, { headers: {"Authorization" : `${JsonWebtoken}`} });
    debugger;

    if (data.status == "SUCCESS") {
      debugger

      alert("Ticket Cancelled Successfully!");
      // window.history.goBack();
      window.history.back();
      var authData = JSON.parse(sessionStorage.getItem("authData"));
      debugger;
    
      // window.location.href = SUPPORT_URL+"/"+authData.token+"/"+authData.phone_number+"/"+authData.name+"/"+authData.country_code+"/ft";

    }
    else if(data.status == "REQUESTED") {
      debugger
      alert("Ticket Cancellation Requested Successfully!");
      // window.history.goBack();
      window.history.back()
      var authData = JSON.parse(sessionStorage.getItem("authData"));

      window.location.href = SUPPORT_URL+"/"+authData.token+"/"+authData.phone_number+"/"+authData.name+"/"+authData.country_code+"/ft";

    }
    else{
      debugger
      swal(data.response.errors[0].message);
      // location.reload();
    }

    dispatch({
      type: CANCEL_DETAIL_SUCCESS,
      payload: data,
    });
  } 
  catch (error) {
    debugger
    if(error.response.status == 401){
      logoutFun()
      console.log(WEBSITE_URL+'logout/')
      window.location.href = WEBSITE_URL+'logout/';
    }
    dispatch({
      type: CANCEL_DETAIL_FAILURE,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};

// get user data on profile page
export const getUserDataAction = (reqData) => async (dispatch) => {
  try {
    dispatch({ type: USER_DATA_REQUEST });

    const API = API_URL + "/accounts" + "/get-user-data/";
    const reqdata = reqData;
 
    let token = getToken()
    
    const { data } = await axios.post(API, reqdata, { headers: {"Authorization" : `${JsonWebtoken}`} });


    dispatch({
      type: USER_DATA_SUCCESS,
      payload: data,
    });
  } catch (error) {
    debugger;
    // if(error.response.status == 401){
    //   logoutFun()
    //   console.log(WEBSITE_URL+'logout/')
    //   window.location.href = WEBSITE_URL+'logout/';
    // }
    dispatch({
      type: USER_DATA_FAILURE,
      payload:
        error.response? error.response
          : error.message,
    });
  }
};
// get invoice data
export const getInvoiceDataAction = (reqData) => async (dispatch) => {
  try {
    dispatch({ type: USER_DATA_REQUEST });

    const API = API_URL + "/accounts" + "/send-invoice-data/";
    const reqdata = reqData;
    var token = getToken()
    const { data } = await axios.post(API, reqdata, { headers: {"Authorization" : `${JsonWebtoken}`} });
    // console.log(data);

    dispatch({
      type: USER_DATA_SUCCESS,
      payload: data,
    });
  } catch (error) {
    if(error.response.status == 401){
      logoutFun()
      console.log(WEBSITE_URL+'logout/')
      window.location.href = WEBSITE_URL+'logout/';
    }
    dispatch({
      type: USER_DATA_FAILURE,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};
// get user data on profile page
export const setUserDataAction = (reqData) => async (dispatch) => {
  try {
    dispatch({ type: USER_DATA_REQUEST });

    const API = API_URL + "/accounts" + "/set-user-data/";
    const reqdata = reqData;
    var token = getToken()
    const { data } = await axios.post(API, reqdata, { headers: {"Authorization" : `${JsonWebtoken}`} });
  
    alert("successfully updated")
    window.location.reload();
    
    dispatch(
      {
      type: USER_DATA_SUCCESS,
      payload: data,
    });
  } catch (error) {
    if(error.response.status == 401){
      logoutFun()
      console.log(WEBSITE_URL+'logout/')
      window.location.href = WEBSITE_URL+'logout/';
    }
    dispatch({
      type: USER_DATA_FAILURE,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};


// get user data on profile page
export const setDateChangeAction = (reqData) => async (dispatch) => {
  debugger;
  try {
    dispatch({ type: DATE_CHANGE_REQUEST });

    const API = API_URL +"/cancel-tripjack/"+"date-change/";
    const reqdata = reqData;
    var token = getToken()
    const { data, status } = await axios.post(API, reqdata, { headers: {"Authorization" : `${JsonWebtoken}`} });
    
    console.log("fffffffffffffffffffff")
    console.log(status)
    // alert("successfully updated")
    // window.location.reload();
    
    if(data.status == 200){
      // alert(data.success)
      swal(data.success)
      window.location.reload();

      
    }
    else if(data.status == 404){
      alert(data.error)
      window.location.reload();
    }
    
    dispatch(
      {
      type: DATE_CHANGE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    if(error.response.status == 401){
      logoutFun()
      console.log(WEBSITE_URL+'logout/')
      window.location.href = WEBSITE_URL+'logout/';
    }
    // alert(error.message)
    dispatch({
      type: DATE_CHANGE_FAILURE,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};


// get user data on profile page
export const updateDateAction = (reqData, URLDATA) => async (dispatch) => {
  try {
    dispatch({ type: USER_DATA_REQUEST });

    const API = API_URL + URLDATA;
    const reqdata = reqData;
    var token = getToken()
    const { data } = await axios.post(API, reqdata,{ headers: {"Authorization" : `${JsonWebtoken}`} });

    
    alert("successfully updated")
    // window.location.reload();
    
    dispatch(
      {
      type: USER_DATA_SUCCESS,
      payload: data,
    });
  } catch (error) {
    if(error.response.status == 401){
      logoutFun()
      console.log(WEBSITE_URL+'logout/')
      window.location.href = WEBSITE_URL+'logout/';
    }
    dispatch({
      type: USER_DATA_FAILURE,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};




export const genericApiCall =(reqData, apiEndpoint, requestType, successType, failureType, type)=> async(dispatch)=>{
  try {
    dispatch({ type: requestType });
    debugger;
    const API = API_URL+apiEndpoint;
    const reqdata = reqData;
    
    if(type == POST){
      var token = getToken()
      const { data } = await axios.post(API, reqData, { headers: {"Authorization" : `${JsonWebtoken}`} });
      dispatch(
        {
        type: successType,
        payload: data,
      });
    }
    else if(type == GET){
      var token = getToken()
      const { data } = await axios.get(API, reqData, { headers: {"Authorization" : `${JsonWebtoken}`} });
      dispatch(
        {
        type: successType,
        payload: data,
      });
    }
    else if(type == PUT){
      var token = getToken()
      const { data } = await axios.put(API, reqdata, { headers: {"Authorization" : `${JsonWebtoken}`} });
      dispatch(
        {
        type: successType,
        payload: data,
      });
    }


  } catch (error) {
    if(error.response.status == 401){
      logoutFun()
      console.log(WEBSITE_URL+'logout/')
      window.location.href = WEBSITE_URL+'logout/';
    }
    dispatch({
      type: failureType,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
}