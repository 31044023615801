import {
  Container,
  Navbar,
  Image,
  Nav,
  Form,
  FormControl,
  FormGroup,
  Button,
  Tab,
  Tabs,
  Row,
  Col,
  Card,
  CardBody,
} from "react-bootstrap";
import modulecss from "../cssmodules/UserForm.module.css";
import React, { useState,useEffect } from "react";
import Alert from "react-bootstrap/Alert";
import DPHeader from "../components/DPHeader";
import axios from "axios";
import { API_URL, SUPPORT_URL } from "../Constant";
import { useParams } from 'react-router-dom'


const DirectPay = () => {

	const [formData, setFormData] = useState({
		name: undefined,
		email: undefined,
		phone: undefined,
		amount: undefined,
		payforservices: undefined,
		reason: undefined,
	});
	const [isName, setIsName] = useState(true);
	const [isEmail, setIsEmail] = useState(true);
	const [isPhone, setIsPhone] = useState(true);
	const [isAmount, setIsAmount] = useState(true);
	const [isPayForServices, setIsPayForServices] = useState(true);
	const [isReason, setIsReason] = useState(true);
	const [OrderId, setOrderId] = useState(true);
  const [data, setData] = useState();

	const [Name, setName] = useState('');
	const [Email, setEmail] = useState('');
	const [Phone, setPhone] = useState('');
	const [Amount, setAmount] = useState('');
	const [PayForServices, setPayForServices] = useState('');
	const [Reason, setReason] = useState('');
	const { id } = useParams();
	useEffect(()=>{
		
		const fetchData = async () => {
        debugger
        const DisplayDirectpay = await axios.get(API_URL + '/Aggrepayment/DisplayDirectpaystore/')
        // console.log("DisplayDirectpay.data")
        // console.log(DisplayDirectpay.data)
        setData(DisplayDirectpay.data)
        debugger
        sessionStorage.setItem("DisplayDirectpay", JSON.stringify(DisplayDirectpay.data));


    };
    fetchData();
		axios.post(API_URL + "/mispay/mispayments/",{"id": id})
      .then(res => {
      	console.log(res)
      	res.data = {
      		name:res.data.name,
      		email:res.data.email,
      		phone:res.data.contact,
      		amount:res.data.amount,
      		payforservices:res.data.service_display,
      		reason:res.data.reason,
      		created_on:res.data.created_on,
      		order_id:res.data.order_id
      	}
      	let response = res.data;
        setName(response.name);
        setEmail(response.email);
        setPhone(response.phone);
        setAmount(response.amount);
        setPayForServices(response.payforservices);
        setReason(response.reason);
        setFormData({
					name: response.name,
					email: response.email,
					phone: response.phone,
					amount: response.amount,
					payforservices: response.payforservices,
					reason: response.reason,
				});
				let created_on = res.data.created_on;
				let order_id = res.data.order_id;
				setTimeout(() => {
				    handleDisplay(order_id)
				}, 1) 
				handleTime(created_on)
      }).catch(err=>{console.log(err)})
	},[])


	const handleTime = (dataD) => {
		debugger
    let data= new Date(dataD)
    let present_date = new Date()
    var dif = (present_date - data);
    var dif = Math.round((dif/1000)/60);
    if(dif > 20){
    	alert("Link is expired. Please contact to Support +91 8988987777");
    	document.querySelector('#payNow').disabled = true;

    }


  }

  const handleDisplay = async (dataD) => {
    debugger
    const DisplayDirectpay = await JSON.parse(sessionStorage.getItem("DisplayDirectpay")); //get them back

    // console.log("obj")
    // console.log(dataD)
    
    // console.log("working1")
    // console.log("gg",DisplayDirectpay)
    // console.log(DisplayDirectpay)

     DisplayDirectpay?.map((obj) => {
        console.log("working2")


        if (obj.order_id === dataD) {
            debugger
            console.log("working4")
            
            setOrderId(false)
            document.querySelector('#payNow').classList.add("d-none")
            
        }

        else {
            setOrderId(true)
            

        }


    });
          
}

	return (
		<>
			
			<div>
				<DPHeader/>
        			<div className="jumbotron1">
				      <Row>
				        <Form className="mt-5" id="paymentForm"
              
                        action={API_URL + "/payapi/"} method="POST">
				          <Row>
				          	<Col md={2}>
				          	</Col>
				            <Col md={8}>
				              <Card className={modulecss.cardCss}>
				                <Card.Body>
													<div className="col-md-12 row">
														<div className="col-md-4">
															<Form.Group
																className="mb-3"
																controlId="formBasicName"
															>
																<Form.Label>Name<span style={{color: "red"}}>*</span></Form.Label>
																<Form.Control
																	type="text"
																	placeholder="Enter Name" disabled value={Name}
																	
																/>
																<Form.Text
																	className={`text-muted ${
																		isName ? "hide" : "invalidCls"
																	}`}
																>
																	Name is required
																</Form.Text>
															</Form.Group>
														</div>
														<div className="col-md-4">
															<Form.Group
																className="mb-3"
																controlId="formBasicEmail"
															>
																<Form.Label>Email address<span style={{color: "red"}}>*</span></Form.Label>
																<Form.Control
																	type="email"
																	placeholder="Enter Email" disabled value={Email}
																	
																/>
																<Form.Text
																	className={`text-muted ${
																		isEmail ? "hide" : "invalidCls"
																	}`}
																>
																	Email is required
																</Form.Text>
															</Form.Group>
														</div>
														<div className="col-md-4">
															<Form.Group
																className="mb-3"
																controlId="formBasicContact"
															>
																<Form.Label>Contact<span style={{color: "red"}}>*</span></Form.Label>
																
																<input type="number" className="form-control" disabled  value={formData.phone} placeholder="Contact" />
																<Form.Text
																	className={`text-muted ${
																		isPhone ? "hide" : "invalidCls"
																	}`}
																>
																	Contact is required
																</Form.Text>
															</Form.Group>
														</div>
													</div>	
													<div className="col-md-12 row">
														<div className="col-md-4">
															<Form.Group
																className="mb-3"
																controlId="formBasicAmount"
															>
																<Form.Label>Amount<span style={{color: "red"}}>*</span></Form.Label>
																
																<input className="form-control"
																	type="number"
																	placeholder="Amount" disabled  value={formData.amount}
																	

																/>
																<Form.Text
																	className={`text-muted ${
																		isAmount ? "hide" : "invalidCls"
																	}`}
																>
																	Amount is required
																</Form.Text>
															</Form.Group>
														</div>
														<div className="col-md-4">
															<Form.Group
																className="mb-3"
																controlId="formBasicSelectSearvices"
															>
																<Form.Label>Service<span style={{color: "red"}}>*</span></Form.Label>
																<Form.Control
																	type="text"
																	placeholder="Select Services" disabled value={PayForServices}
																	
																/>
																
																<Form.Text
																	className={`text-muted ${
																		isPayForServices ? "hide" : "invalidCls"
																	}`}
																>
																	Service is required
																</Form.Text>
															</Form.Group>
														</div>
														<div className="col-md-4">
															<Form.Group
																className="mb-3"
																controlId="formBasicReason"
															>
																<Form.Label>Description</Form.Label>
																<Form.Control
																	type="text"
																	 disabled value={Reason}
																	

																/>
																<Form.Text
																	className={`text-muted ${
																		isReason ? "hide" : "invalidCls"
																	}`}
																>
																	Description is required
																</Form.Text>
															</Form.Group>
														</div>
													</div>
													<div className="col-md-12 text-center">
														<input type="hidden" name="amount"  value={formData.amount} />
														<input type="hidden" name="id"  value={id} />
														<input type="hidden" name="phone"  value={formData.phone} />
														<input type="hidden" name="services"  value={PayForServices} />
														<input type="hidden" name="reason"  value={Reason} />
														<input id="payNow" variant="primary" type="submit" value="Pay Now"  style={{backgroundColor:"#6D42EF",borderColor:"#6D42EF",color:"white"}} />
															
														
													</div>
													 </Card.Body>
					              </Card>
					          </Col>
					          <Col md={2}>
				          	</Col>
					        </Row>
							</Form>
						 </Row>
					</div>
				</div>
					
		</>
	);
};

export default DirectPay;
