import React from "react";
import {
  Container,
  Navbar,
  Image,
  Nav,
  Form,
  FormControl,
  Button,
  Tab,
  Tabs,
  Row,
  Col
} from "react-bootstrap";
import Header from "../components/Header";
import NavbarComp from "../components/NavbarComp";
import Moment from "react-moment";

function Completed({ trips, type }) {
  console.log("!@#####@!@!@");
  console.log(trips);

  if (type == "ft") {
    return (
      <div>
        <div className="p-3">
          <div className="card p-3 pt-5">
            <div className="row">
              <div className="col-md-1">
                <span>
                  <Image
                    src={
                      process.env.PUBLIC_URL +
                      "/images/booking-summary/completed-empty.png"
                    }
                    alt="GoHopTrip"
                    fluid
                  />
                </span>
              </div>
              <div className="col mrgnAutoCls">
                <span className="headingCls blockCls">Completed Bookings</span>
              </div>
            </div>

            {trips?.map((trip, i) => {
              return (
                <>
                  <div className="card mt-5" style={{"backgroundColor":"#f5f5f5"}}>
                    <div className="p-4">
                      <div className="d-flex flex-row">
                        <div>
                          <span className="headingCls">{trip.from_city}</span>
                        </div>
                        <div className="" style={{"padding":"5px"}}>
                            <Image
                              src={
                                process.env.PUBLIC_URL +
                                "/images/arrow.png"
                              }
                              alt="GoHopTrip"
                              fluid
                            />
                          </div>
                        <div>
                          <span className="headingCls">{trip.to_city}</span>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col">
                          <span className="subDetails">Completed</span>
                          <span> </span>
                          <span className="subDetails">{trip.trip_type}</span>
                          <span> </span>
                          <span className="subDetails">Booking ID</span>
                          <span>-</span>
                          <span className="subDetails">
                            {trip.gohoptrip_booking_id}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card">
                    <div className="p-4">
                      <div className="row">
                        <div className="col">
                          <div className="d-flex flex-column">
                            <div className="pb-1">From</div>
                            <div className="">
                              <Moment format="ddd, DD MMM YY">
                                {trip.departure_time}
                              </Moment>
                            </div>
                            <div className="">{trip.from_airport}</div>
                          </div>
                        </div>
                        <div className="col">
                          <div className="d-flex flex-column">
                            <div className="pb-1">To</div>
                            <div className="">
                              <Moment format="ddd, DD MMM YY">
                                {trip.arrival_time}
                              </Moment>
                            </div>
                            <div className="">{trip.to_airport}</div>
                          </div>
                        </div>
                        <div className="col">
                          <div className="d-flex flex-column">
                            {/* <div className="pb-1">tt</div> */}
                            <div className="">
                              <span>
                                <Image src="" alt="" />
                              </span>
                              <span>{trip.airline_data}</span>
                            </div>
                            {/* <div className="">Priya + 1</div> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              );
            })}
          </div>
        </div>
      </div>
    );
  } 
  else if(type=="ht") 
  {
    return (
      <div>
        <div className="p-3">
          <div className="card p-3 pt-5">
            <div className="row">
              <div className="col-md-1">
                <span>
                  <Image
                    src={
                      process.env.PUBLIC_URL +
                      "/images/booking-summary/completed-empty.png"
                    }
                    alt="GoHopTrip"
                    fluid
                  />
                </span>
              </div>
              <div className="col mrgnAutoCls">
                <span className="headingCls blockCls">Completed Bookings</span>
              </div>
            </div>

            {trips?.map((hotel, i) => {
              console.log("oneway");

              var check_in_date = hotel.check_in_date;
              var check_out_date = hotel.check_out_date;

              return (
                <>
                  <div className="card mt-5" style={{"backgroundColor":"#f5f5f5"}}>
                    <div className="p-4" >
                      <Row>
                        <Col>
                          <div className="d-flex flex-row">
                            <div>
                              <span className="headingCls">
                                {hotel.hotel_name}
                              </span>
                            </div>
                          </div>
                        </Col>

                        <Col style={{ textAlign: "right" }}>
                          {/* <ButtonComp
                            flightIndx={i}
                            triptype={"oneway"}
                            redirectTyp={"about"}
                            bookingData={flight}
                          /> */}
                          
                        </Col>
                      </Row>

                      <div className="row">
                        <div className="col">
                          <span className="subDetails">Completed </span>

                          <span className="subDetails">Booking ID</span>
                          <span>-</span>
                          <span className="subDetails">
                            {hotel.gohoptrip_booking_id}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card">
                    <div className="p-4">
                      <div className="row">
                        <div className="col-md-2">
                          <div className="d-flex flex-column">
                            <div
                              className="pb-1"
                              style={{ fontWeight: "bold" }}
                            >
                              From
                            </div>
                            <div className="">
                              <span>
                                <Moment format="ddd, DD MMM YY">
                                  {check_in_date}
                                </Moment>
                              </span>
                            </div>
                            {/* <div className="">{flight.from_airport}</div> */}
                          </div>
                        </div>
                        <div className="col-md-2">
                          <div className="d-flex flex-column">
                            <div
                              className="pb-1"
                              style={{ fontWeight: "bold" }}
                            >
                              To
                            </div>
                            <div className="">
                              <span>
                                <Moment format="ddd, DD MMM">
                                  {check_out_date}
                                </Moment>
                              </span>
                            </div>
                            {/* <div className="">{flight.to_airport}</div> */}
                          </div>
                        </div>
                        <div className="col-md-2">
                          <div className="d-flex flex-column">
                            {/* <div className="pb-1">tt</div> */}
                            <div className="">
                              <span style={{ fontWeight: "bold" }}>City:</span>
                              <span>{`  ${hotel.city}`}</span>
                            </div>

                            {/* <div className="">Priya + 1</div> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              );
            })}
          </div>
        </div>
      </div>
    );
  }
  else if(type == "bus"){
    return (
      <div>
        <div className="p-3">
          <div className="card p-3 pt-5">
            <div className="row">
              <div className="col-md-1">
                <span>
                  <Image
                    src={
                      process.env.PUBLIC_URL +
                      "/images/booking-summary/completed-empty.png"
                    }
                    alt="GoHopTrip"
                    fluid
                  />
                </span>
              </div>
              <div className="col mrgnAutoCls">
                <span className="headingCls blockCls">Completed Bookings</span>
              </div>
            </div>

            {trips?.map((trip, i) => {
            

              return (
                <>
                  <div className="card mt-5" style={{"backgroundColor":"#f5f5f5"}}>
                    <div className="p-4">
                      <div className="d-flex flex-row">
                        <div>
                          <span className="headingCls">{trip.from_city}</span>
                        </div>
                        <div className="" style={{"padding":"5px"}}>
                            <Image
                              src={
                                process.env.PUBLIC_URL +
                                "/images/arrow.png"
                              }
                              alt="GoHopTrip"
                              fluid
                            />
                          </div>
                        <div>
                          <span className="headingCls">{trip.to_city}</span>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col">
                          <span className="subDetails">Completed</span>
                          <span> </span>
                          <span className="subDetails">{trip.trip_type}</span>
                          <span> </span>
                          <span className="subDetails">Booking ID</span>
                          <span>-</span>
                          <span className="subDetails">
                            {trip.gohoptrip_booking_id}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card">
                    <div className="p-4">
                      <div className="row">
                        <div className="col">
                          <div className="d-flex flex-column">
                            <div className="pb-1">Travel Date</div>
                            <div className="">
                              <Moment format="ddd, DD MMM YY">
                                {trip.departure_date}
                              </Moment>
                            </div>
                            <div className="">{trip.from_airport}</div>
                          </div>
                        </div>
                        {/* <div className="col">
                          <div className="d-flex flex-column">
                            <div className="pb-1">To</div>
                            <div className="">
                              <Moment format="ddd, DD MMM">
                                {trip.arrival_date}
                              </Moment>
                            </div>
                            <div className="">{trip.to_airport}</div>
                          </div>
                        </div> */}
                        <div className="col">
                          <div className="d-flex flex-column">
                            {/* <div className="pb-1">tt</div> */}
                            <div className="">
                              <span>
                                <Image src="" alt="" />
                              </span>
                              <span>{trip.airline_data}</span>
                            </div>
                            {/* <div className="">Priya + 1</div> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              );
            })}
          </div>
        </div>
      </div>
    );
  }
}

export default Completed;
