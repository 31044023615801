
import React, { useState, useEffect } from "react";
import {
  Container,
  Navbar,
  Image,
  Nav,
  Form,
  FormControl,
  Button,
  Tab,
  Tabs,
  Card,
  Col,
  Row,
  CardBody,
  ListGroup,
  Badge
} from "react-bootstrap";
import { updateDateAction } from "../actions/userdetailAction";
import modulecss from "../cssmodules/Flightdetail.module.css";
import ButtonComp from "./ButtonComp";
import { useDispatch, useSelector } from "react-redux";
import Moment from "react-moment";
import { CANCEL, MULTICITY, ONEWAY, ROUNDTRIP, TRIPJACK } from "../Constant";
import axios from "axios";
import { API_URL, SUPPORT_URL } from "../Constant";


function Flightdetail({ bookingDetails, tripType, tripType_sec }) {
  debugger

  // console.log("lafffffffffffffffffffff")
  // console.log(bookingDetails)
  const [aTripState,setaTripState] = useState('')
  useEffect(()=>{
       axios.post(API_URL + "/cancel-tripjack" + "/check_amendment_status/", { "booking_id":bookDetailsData.Flight_booking_id})
        .then(res => {
          if(res.data.success != "No Cancellation Raised"){
            setaTripState(res.data.success)
          }
        })
    },[aTripState])

  const dispatch = useDispatch();

  const bookDetailsData = bookingDetails;
  const apiBookData = JSON.parse(localStorage.getItem("bookDetailsKey"));
  if(bookDetailsData.apiType != 'TBO'){
    if (
      apiBookData.itemInfos.AIR.tripInfos[0].sI[0].dt.split("T")[0] !==
      bookingDetails.departure_time.split("T")[0]
    ) {
      var date_dict = {
        arival_date: apiBookData.itemInfos.AIR.tripInfos[0].sI[0].at,
        dept_date: apiBookData.itemInfos.AIR.tripInfos[0].sI[0].dt,
        apiType: TRIPJACK,
      };
      dispatch(updateDateAction(date_dict, "/hotel/hotel_booking_details/"));
    }

  }
  



  // if(tripState == "PENDING" || tripState == "ON_HOLD"){

  // }
  var travDetails
  if(bookDetailsData.apiType == 'TBO'){
    travDetails = apiBookData.Response.FlightItinerary.Passenger;
  }else if(bookDetailsData.apiType == 'TRIPJACK'){
    travDetails = apiBookData.itemInfos.AIR.travellerInfos;
  }

  // console.log(travDetails);
  localStorage.setItem(
    "tripType_sec",
    JSON.stringify(bookDetailsData.tripType_sec)
  );
  localStorage.setItem("trip_type", JSON.stringify(bookDetailsData.trip_type));

  const bookDict = {
    departure_date: null,
    arrival_date: null,
    departure_time: null,
    arrival_time: null,
    from_city: null,
    to_city: null,
    airline_data: null,
    frm_city_code: null,
    to_city_code: null,
    imageField: null,
  };
  debugger;
  // for one way flight details
  if (tripType == ONEWAY) {

    if(tripType_sec == 'ONWARD' || tripType_sec == 'NONE' || tripType_sec == 'FT'){
      var tripState
      if(bookDetailsData.apiType == 'TBO'){
        tripState = JSON.parse(localStorage.getItem("bookDetailsKey")).Response.FlightItinerary.Status;
        tripState = tripState == 0 ? 'Failed' : tripState == 1 ? 'SUCCESS' : tripState == 2 ? 'NotSaved' : tripState == 3 ? 'NotCreated' : tripState == 4 ? 'NotAllowed': tripState == 5 ? 'InProgress' : tripState == 6 ? 'TicketeAlreadyCreated' : tripState == 8 ? 'PriceChanged' : tripState == 9 ? 'OtherError' : 'Failed' 
        if(bookingDetails.is_international){
          let arr=[]
          let main_data = JSON.parse(localStorage.getItem("bookDetailsKey"))
         
          main_data.Response.FlightItinerary.map((i,v)=>{
            main_data.Response.FlightItinerary.Segments.map((k,m)=>{
              arr.push(main_data.Response.FlightItinerary.Segments[m])
            })
          })
          var trips = arr
        }else{
          var trips = JSON.parse(localStorage.getItem("bookDetailsKey")).Response.FlightItinerary.Segments;
        }
      }
      else if(bookDetailsData.apiType == 'TRIPJACK'){
        tripState = JSON.parse(localStorage.getItem("bookDetailsKey")).order.status;
        if(bookingDetails.is_international){
          let arr=[]
          let main_data = JSON.parse(localStorage.getItem("bookDetailsKey"))
         
          main_data.itemInfos.AIR.tripInfos.map((i,v)=>{
            main_data.itemInfos.AIR.tripInfos[v].sI.map((k,m)=>{
              arr.push(main_data.itemInfos.AIR.tripInfos[v].sI[m])
            })
          })
          var trips = arr
        }else{
          var trips = JSON.parse(localStorage.getItem("bookDetailsKey")).itemInfos.AIR
          .tripInfos[0].sI;
        }
      }
      
      
     
    }
    else if(tripType_sec == 'RETURN' || tripType_sec == 'SC'){ /////Pending
      var tripState
      if(bookDetailsData.apiType == 'TBO'){
        tripState = JSON.parse(localStorage.getItem("bookDetailsKey")).Response.FlightItinerary.Status;
        tripState = tripState == 0 ? 'Failed' : tripState == 1 ? 'SUCCESS' : tripState == 2 ? 'NotSaved' : tripState == 3 ? 'NotCreated' : tripState == 4 ? 'NotAllowed': tripState == 5 ? 'InProgress' : tripState == 6 ? 'TicketeAlreadyCreated' : tripState == 8 ? 'PriceChanged' : tripState == 9 ? 'OtherError' : 'Failed' 
        if(bookingDetails.is_international){
          let arr=[]
          let main_data = JSON.parse(localStorage.getItem("bookDetailsKey"))
         
          main_data.Response.FlightItinerary.map((i,v)=>{
            main_data.Response.FlightItinerary.Segments.map((k,m)=>{
              arr.push(main_data.Response.FlightItinerary.Segments[m])
            })
          })
          var trips = arr
        }else{
          var trips = JSON.parse(localStorage.getItem("bookDetailsKey")).Response.FlightItinerary.Segments;
        }
      }
      else if(bookDetailsData.apiType == 'TRIPJACK'){
        tripState = JSON.parse(localStorage.getItem("bookDetailsKey")).order
        .status;
        if(bookingDetails.is_international){
          let arr=[]
          let main_data = JSON.parse(localStorage.getItem("bookDetailsKey"))
         
          main_data.itemInfos.AIR.tripInfos.map((i,v)=>{
            main_data.itemInfos.AIR.tripInfos[v].sI.map((k,m)=>{
              arr.push(main_data.itemInfos.AIR.tripInfos[v].sI[m])
            })
          })
          var trips = arr
        }else{
          var trips = JSON.parse(localStorage.getItem("bookDetailsKey")).itemInfos.AIR
        .tripInfos[1].sI;
        }
      }
      
    }
    else if(tripType_sec == 'TH'){ /////Pending
      var tripState
      if(bookDetailsData.apiType == 'TBO'){
        tripState = JSON.parse(localStorage.getItem("bookDetailsKey")).Response.FlightItinerary.Status;
        tripState = tripState == 0 ? 'Failed' : tripState == 1 ? 'SUCCESS' : tripState == 2 ? 'NotSaved' : tripState == 3 ? 'NotCreated' : tripState == 4 ? 'NotAllowed': tripState == 5 ? 'InProgress' : tripState == 6 ? 'TicketeAlreadyCreated' : tripState == 8 ? 'PriceChanged' : tripState == 9 ? 'OtherError' : 'Failed' 
        if(bookingDetails.is_international){
          let arr=[]
          let main_data = JSON.parse(localStorage.getItem("bookDetailsKey"))
         
          main_data.Response.FlightItinerary.map((i,v)=>{
            main_data.Response.FlightItinerary.Segments.map((k,m)=>{
              arr.push(main_data.Response.FlightItinerary.Segments[m])
            })
          })
          var trips = arr
        }else{
          var trips = JSON.parse(localStorage.getItem("bookDetailsKey")).Response.FlightItinerary.Segments;
        }
      }
      else if(bookDetailsData.apiType == 'TRIPJACK'){
        tripState = JSON.parse(localStorage.getItem("bookDetailsKey")).order
        .status;
        if(bookingDetails.is_international){
          let arr=[]
          let main_data = JSON.parse(localStorage.getItem("bookDetailsKey"))
         
          main_data.itemInfos.AIR.tripInfos.map((i,v)=>{
            main_data.itemInfos.AIR.tripInfos[v].sI.map((k,m)=>{
              arr.push(main_data.itemInfos.AIR.tripInfos[v].sI[m])
            })
          })
          var trips = arr
        }else{
          var trips = JSON.parse(localStorage.getItem("bookDetailsKey")).itemInfos.AIR
        .tripInfos[2].sI;
        }
      }
      
      
      
    }
    bookDict.departure_date = bookDetailsData.departure_time.split("T")[0];

    bookDict.arrival_date = bookDetailsData.arrival_time.split("T")[0];

    // get time from datetime format
    bookDict.departure_time = bookDetailsData.departure_time
      .split("T")[1]
      .substring(0, 5);
    // get time from datetime format
    bookDict.arrival_time = bookDetailsData.arrival_time
      .split("T")[1]
      .substring(0, 5);

    bookDict.airline_data = bookDetailsData.airline_data;

    bookDict.from_city = bookDetailsData.from_city;
    bookDict.to_city = bookDetailsData.to_city;

    bookDict.frm_city_code = bookDetailsData.frm_city_code;
    bookDict.to_city_code = bookDetailsData.to_city_code;
    bookDict.imageField = bookDetailsData.imageField;
    
  }


  return (
    <Card className={`${modulecss.mainCard} p-4`}>
      <Card.Body>
        <Row>
          <Col md={4}>
            <span>
              <h5>Review your Booking</h5>
            </span>
          </Col>
          <Col>
            <span className="subHeadCls">{`(${bookDict.from_city}`}</span>
            <span class="material-icons-outlined">
              <Image
                src={process.env.PUBLIC_URL + "/images/arrow.png"}
                alt="GoHopTrip"
                className="p-2"
                fluid
              />
            </span>
            <span className="subHeadCls">{`${bookDict.to_city})`}</span>
          </Col>

          <Col md={4} style={{ justifyContent: "end" }}>
            <span>Booking Status: </span>
            <span
              className={
                tripState == "SUCCESS" ? "success_class" : "failure_class"
              }
            >
                  
                   {aTripState?<Badge bg="warning" text="dark">CANCELLATION{aTripState}</Badge>:<strong>{tripState=="SUCCESS"?<Badge bg="success">Success</Badge>:<Badge bg="danger">{tripState}</Badge>}</strong>}                           
            </span>
          </Col>
        </Row>
        {trips?.map((trip, indx) => {

          
          var aIcode,aIname,tripfN,da_cityCode,DepTime,da_name,aa_cityCode,ArrTime,aa_name
          if(bookDetailsData.apiType == 'TBO'){
            aIcode = trip.Airline.AirlineCode
            aIname = trip.Airline.AirlineName
            tripfN = trip.Airline.FlightNumber
            da_cityCode = trip.Origin.Airport.CityCode
            DepTime = trip.Origin.DepTime.split("T")
            da_name = trip.Origin.Airport.AirportName
            aa_cityCode = trip.Destination.Airport.CityCode
            ArrTime = trip.Destination.ArrTime.split("T")
            aa_name = trip.Destination.Airport.AirportName
          }
          else if(bookDetailsData.apiType == 'TRIPJACK'){
            aIcode = trip.fD.aI.code
            aIname = trip.fD.aI.name
            tripfN = trip.fD.fN
            da_cityCode = trip.da.cityCode
            DepTime = trip.dt.split("T")
            da_name = trip.da.name
            aa_cityCode = trip.aa.cityCode
            ArrTime = trip.at.split("T")
            aa_name = trip.aa.name
          }

          return (
            <Card className={`p-2 mt-3 ${modulecss.flightCard}`} key={indx}>
              <Row className="">
                <Col className="colblock" style={{ margin: "auto", textAlign: "center" }}>
                  <div class="d-flex flex-column bd-highlight mb-3 setImgMargin">
                    <div class="bd-highlight">
                      <Image className="review_image"
                        src={`//static.tripjack.com/img/airlineLogo/${aIcode}.png`} onError={({ currentTarget }) => {currentTarget.onerror = null; // prevents looping
                          currentTarget.src=process.env.PUBLIC_URL + "/images/no_airline.png";}}
                      />
                    </div>
                    <div class="bd-highlight">
                      <strong className="sub_font">{aIname}</strong>
                    </div>
                    <div class=" bd-highlight">
                      <span>{`${aIcode} | ${tripfN}`}</span>
                    </div>
                  </div>
                </Col>

                <Col className="colblock" style={{ margin: "auto", textAlign: "center" }}>
                  <span className="sub_font" style={{ display: "block" }}>
                    {`${da_cityCode} ${DepTime[1]}`}
                  </span>
                  <span style={{ display: "block" }}>
                    <Moment format="ddd, DD MMM YYYY">
                      {DepTime[0]}
                    </Moment>
                  </span>
                  <span
                    className={modulecss.bottomTxt}
                    style={{ display: "block" }}
                  >
                    {da_name}
                  </span>
                  {/* <span className={modulecss.bottomTxt} style={{ display: "block" }}>Terminal 2</span> */}
                </Col>
                <Col className="colblock" style={{ margin: "auto", textAlign: "center" }}>
                  <span>--------------------</span>
                </Col>
                <Col className="colblock" style={{ margin: "auto", textAlign: "center" }}>
                  <span className="sub_font" style={{ display: "block" }}>
                    {`${aa_cityCode} ${ArrTime[1]}`}
                  </span>
                  <span style={{ display: "block" }}>
                    <Moment format="ddd, DD MMM YYYY">
                      {ArrTime[0]}
                    </Moment>
                  </span>
                  <span
                    className={modulecss.bottomTxt}
                    style={{ display: "block" }}
                  >
                    {aa_name}
                  </span>
                  {/* <span className={modulecss.bottomTxt} style={{ display: "block" }}>Terminal 2</span> */}
                </Col>
              </Row>
            </Card>
          );
        })}
        <Row>
          <Col md={7} className="mt-4">
            <ListGroup variant="flush">
              <ListGroup.Item>
                <strong>Passangers</strong>
              </ListGroup.Item>
            </ListGroup>
            <ListGroup>
              {travDetails?.map((trav, indx) => {
                
               var passfN,passlN
               if(bookDetailsData.apiType == 'TBO'){
                passfN = trav.FirstName
                passlN = trav.LastName
               }
               else if(bookDetailsData.apiType == 'TRIPJACK'){
                passfN = trav.fN
                passlN = trav.lN
               }

                return (
                  <>
                    <ListGroup.Item className="sub_font" key={indx}>
                      <Row>
                        <div class="d-flex flex-row">
                          <span className="sub_font">{`${indx + 1})`}</span>
                          <span
                            className="ml-2"
                            style={{ marginLeft: "1em" }}
                          >{`${
                            passfN.charAt(0).toUpperCase() + passfN.slice(1)
                          } ${
                            passlN.charAt(0).toUpperCase() + passlN.slice(1)
                          }`}</span>

                          <span
                            className={`cancelAmdCls ${
                              trav.amd_type == "DATE_CHANGE"
                                ? "dateChangeAmdCls "
                                : "CANCEL"
                                ? "cancelAmdCls"
                                : "hide"
                            }`}
                          >
                            {trav.amd_type == "DATE_CHANGE"
                              ? "[Reissue request raised] "
                              : trav.amd_type == "CANCEL"
                              ? "[cancel request raised]"
                              : ""}
                          </span>
                        </div>
                      </Row>
                      <Row></Row>
                    </ListGroup.Item>
                  </>
                );
              })}
            </ListGroup>
          </Col>
        </Row>
        {/* <Row className="pt-5">
          <Col>
            <ButtonComp redirectTyp={CANCEL} segment={half} />
          </Col>
        </Row> */}
      </Card.Body>
    </Card>
  );
  
}

export default Flightdetail;
